import React,{useRef, useEffect, useState} from 'react'
import { Col, GridWrapper, Row } from '../../../../globalComponents/Grid'
import { ImageV2 } from '../../../../globalComponents/Image'
import mainImage from '../../../../assets/images/theStory.png'
import { Typography } from '../../../../globalComponents/Typography'
import StoryItemComponent from './StoryItemComponent'
import Fader from '../../../../globalComponents/Fader'
import Arrow from '../../../../assets/images/arrow.png'

const TheStory = ({setRef, data, create, setCreate, get}) => {

    const ref = useRef()
    useEffect(() => {setRef(ref)},[])

    const [readMore, setReadMore] = useState(false)

    return(
        <GridWrapper>
            
            <Fader>
            <Row overrides={{marginTop:'150px'}}>
                <Col overrides={{backgroundColor:'#222222', height:'3px'}}/>
            </Row>
            </Fader>

            <Fader>
            <div style={{width:'100%', marginTop:'20px'}} ref={ref}>
                <Row>
                    <Col>
                        <Typography.Header fontSize='50px' bold>The Story</Typography.Header>
                    </Col>
                </Row>
            </div>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'20px'}}>
                <Col overrides={{backgroundColor:'#222222', height:'3px'}}/>
            </Row>
            </Fader>

            <Row overrides={{marginTop:'15px'}}>
                <Col width={10}>
                    <ImageV2 image={data[0].image}/>
                </Col>
            </Row>
            <Row overrides={{marginTop:'25px'}}>
                <Col width={10} overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular fontSize={'17px'} overrides={{fontFamily:'StolzlBook', textAlign:'left'}}>
                        <div dangerouslySetInnerHTML={{ __html: data[0].text }}></div>
                    </Typography.Regular>
                </Col>
            </Row>

            {readMore && data.slice(1).map((item, index) => {
                return (
                    <Fader>
                        <StoryItemComponent key={`storyItem_${index}`} get={get} item={item} />
                    </Fader>
                )
            })}

            {!readMore &&
                <Row overrides={{marginTop:'20px'}}>
                    <Col width={3} overrides={{alignItems:'flex-start'}}>
                        <div onClick={() => setReadMore(true)}>
                            <Typography.Regular overrides={{display:'flex', alignItems:'center', flexDirection:'row', fontFamily:'StolzlRegular'}}>
                                Read More 
                                <ImageV2 overrides={{width:'25px', marginLeft:'10px'}} image={Arrow}/>
                            </Typography.Regular>
                        </div>
                    </Col>    
                </Row>
            }

        </GridWrapper>
    )
}

export default TheStory