import React from 'react'
import { GridWrapper } from "./globalComponents/Grid";
import { ContextProvider } from './context/_index';
import ScreenSelector from './globalComponents/ScreenSelector'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <div style={appStyle}>
      <ContextProvider>
        <GridWrapper>
          <ScreenSelector/>
          <ToastContainer/>
        </GridWrapper>
      </ContextProvider>
    </div>
  );
}

const appStyle = {
  width:'100%',
  backgroundColor:'#F6F6F6',
  minHeight:'100vh'
}

export default App;