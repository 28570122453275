import React, {useState} from 'react'

export const GridWrapper = ({children, overrides, showGrid}) => {

    return(
        <div   
            style={{
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent:'center', 
                alignItems:'center', 
                flex:'1',
                width:'100%', 
                border: showGrid && 'dotted black 5px',
                ...overrides
            }}
        >
            {children}
        </div>
    )
}

export const Row = ({children, overrides, showGrid, clickable}) => {

    return(
        <div 
            style={{
                display:'flex', 
                flexDirection:'row', 
                justifyContent:'center', 
                alignItems:'center', 
                width:'100%', 
                border: showGrid && 'solid blue 2px', 
                boxSizing:'border-box',
                ...overrides
            }}
        >
            {children}
        </div>
    )
}

export const Col = ({children, overrides, width, showGrid, clickable, hoverStyles}) => {


    // const mergeHoverStyles = () => {
    //     if(hover) {
    //         return {
    //             ...hoverStyles
    //         }
    //     }
    //     return {}
    // }

    return(
        <div 
            style={{
                flex: width, 
                display:'flex', 
                flexDirection:'column', 
                width:'100%', 
                height:'100%',
                justifyContent:'center', 
                alignItems:'center',
                border: showGrid && 'solid red 3px', 
                boxSizing:'border-box', 
                //cursor: hover === true ? 'pointer' : 'default',
                //...mergeHoverStyles(),
                ...overrides
            }}
                onClick={clickable ? () => clickable() : null} 
                // onMouseEnter={clickable ? () => setHover(true) : () => {}}
                // onMouseLeave={clickable ? () => setHover(false) : () => {}}
        >
            {children}
        </div>
    )
}

const Grid = {
    GridWrapper,
    Row,
    Col
}

export default Grid