import React,{ useEffect, useRef, useContext} from 'react'
import { ContextManager } from '../../../../context/_index'
import {GridWrapper, Row, Col} from '../../../../globalComponents/Grid'
import { Typography } from '../../../../globalComponents/Typography'
import {EventsList} from '../../EventsComponents'
import { MaskedImageColumns } from '../../../../globalComponents/Image'
import Button from '../../../../globalComponents/Button'
import { Link } from 'react-router-dom'
import ImageDisplay from '../ImageDisplay'
import Fader from '../../../../globalComponents/Fader'
import Separator from '../../../../globalComponents/Separator'
import Lottie from 'lottie-react'
import LottieAnim from '../../../../assets/lottie/upcoming.json'
import { useState } from 'react'

const Brum = ({setRef, get, pageData, imagesData, eventsData}) => {

    const {refPositions} = useContext(ContextManager.RefPosition)

    const [hover, setHover] = useState(false)

    const ref = useRef()
    useEffect(() => {setRef(ref)},[])

    const scrollToContact = () => {
        refPositions?.contact?.current?.scrollIntoView({behavior:'smooth'})
    }

    return(
        <GridWrapper overrides={{marginTop:'70px'}}>

            <Fader>
            <Row>
                <Col>
                    <MaskedImageColumns height={'260px'} image={pageData.brumImage}/>
                </Col>
            </Row>
            </Fader>

            <Fader>
            <div style={{marginTop:'52px', width:'100%'}} ref={ref}>
                <Separator text={'Artsy Party - BRUM'}/>
            </div>
            </Fader>

            <Fader>
            <Row overrides={{alignItems:'flex-start', marginTop:'15px'}}>
                <Col width={10} overrides={{alignItems:'flex-start'}}>
                    <Typography.Header overrides={{marginLeft:'-10px', fontSize:'185px', lineHeight:'80%'}} bold>BRUM</Typography.Header>
                    <Typography.Regular overrides={{fontFamily:'StolzlBook', marginTop:'25px'}}>
                        <div dangerouslySetInnerHTML={{ __html: pageData.brumText }}></div>
                    </Typography.Regular>
                    <Button f={() => scrollToContact()} overrides={{marginTop:'30px', height:'44px'}}>Get in Touch</Button>
                </Col>
                <Col width={1}/>
                <Col width={10}>
                    <Link to="/imagegallery/brum" style={{width:'100%', textDecoration:'none'}}>
                        <ImageDisplay images={imagesData}/>
                        <div style={{marginTop:'-10px', width:'100%'}}>
                            <Separator fontFamily={'StolzlMedium'} text={'View Gallery'} isLink linkTo='/imagegallery/brum' bold={false} lineHeight={'2px'} includeLottie/>
                        </div>
                    </Link>
                </Col>
            </Row>
            </Fader>
            
            <Fader>
            <Row overrides={{marginTop:'100px'}}>
                <Col overrides={{alignItems:'flex-start'}}>
                    <Row>
                        <Col width={1} overrides={{alignItems:'flex-start'}}>
                            <Typography.Header fontSize='70px' bold>Upcoming</Typography.Header>
                        </Col>
                        <Col width={2} overrides={{alignItems:'flex-start'}}>
                            <Lottie style={{width:'80px', marginLeft:'10px'}} animationData={LottieAnim}/>
                        </Col>
                    </Row>
                    <Typography.Header overrides={{marginTop:'-5px'}} fontSize='70px' bold>Events</Typography.Header>
                </Col>
            </Row>
            </Fader>

            <Fader>
                <EventsList events={eventsData} linkTo={'/events/brum'}/>
            </Fader>
        </GridWrapper>
    )
}

export default Brum