import { toast } from 'react-toastify'
export const checkImagePropertiesOnUpload = (newImage) => {
    if(!newImage) {
        toast("No image selected")
        return false
    }
    if(newImage.size > 10500000){
        toast("Max image size is 10MB")
        return false
    }
    if(newImage.type !== 'image/jpeg'){
        toast("Image must be a jpeg file")
        return false
    }
    return true
}