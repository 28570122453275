import React, {useState, useEffect } from 'react'
import {GridWrapper, Row, Col} from '../../../globalComponents/Grid'
import Header from './Header'
import { EventsGrid } from '../EventsComponents'
import useBrumEvents from '../../../dataHooks/useBrumEvents'
import useBrumEventsPage from '../../../dataHooks/useBrumEventsPage'
import { AnimatePresence, motion } from 'framer-motion'
import Loading from '../Loading'

const BrumEvents = () => {

    const [ready, setReady] = useState(false)

    const {
        brumEventsPageData,
        brumEventsPageImagesLoaded,
        getBrumEventsPage
    } = useBrumEventsPage()
    
    const {
        brumEventsData,
        brumEventImagesLoaded,
        getBrumEvents
    } = useBrumEvents()

    return (
        <GridWrapper>
            <AnimatePresence>
                {!(ready && brumEventsData && brumEventsPageData && brumEventImagesLoaded && brumEventsPageImagesLoaded) && <Loading setReady={setReady}/>}
                {(ready && brumEventsData && brumEventsPageData && brumEventImagesLoaded && brumEventsPageImagesLoaded) && 
                    <motion.div
                        key={"unique_mainPageanimation_key"}
                        exit={{ opacity:0 }}
                        initial={{ opacity:0 }}
                        animate={{ opacity:1 }}
                        transition={{duration:1, delay: 1}}
                    >
                    <GridWrapper overrides={{marginBottom:'100px', width:'1100px'}}>

                        <Row>
                            <Col>
                                <Header 
                                    text={brumEventsPageData.text} 
                                    mainImage={brumEventsPageData.mainImage}
                                />
                            </Col>
                        </Row>

                        <Row overrides={{marginTop:'25px'}}>
                            <Col width={15}>
                                <EventsGrid events={brumEventsData} />
                            </Col>
                        </Row>
                    </GridWrapper>
                </motion.div>}
            </AnimatePresence>
        </GridWrapper>
    )
}

export default BrumEvents