import React,{useRef, useEffect} from 'react'
import Button from '../../../../globalComponents/Button'
import { GridWrapper, Row, Col } from '../../../../globalComponents/Grid'
import { Typography, TypographyTitan } from '../../../../globalComponents/Typography'
import Fader from '../../../../globalComponents/Fader'
import { CentralizedBackgroundImage } from '../../../../globalComponents/Image'
import instaImage from '../../../../assets/images/instaImage.png'

const Contact = ({setRef, data, get}) => {

    const ref = useRef()
    useEffect(() => {setRef(ref)},[])

    return(
        <GridWrapper overrides={{marginTop:'30px'}}>
            <div ref={ref}></div>
            <Fader>
            <Row overrides={{marginTop:'20px'}}>
                <Col overrides={{backgroundColor:'#222222', height:'3px'}}/>
            </Row>
            </Fader>
            
            <Fader>
            <Row>
                <Col overrides={{marginTop:'10px'}}>
                    <Typography.Header fontSize='60px' bold>Contact</Typography.Header>
                </Col>
            </Row>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'15px'}}>
                <Col>
                    <div style={{width:'100%', height:'3px', backgroundColor:'#222222'}}></div>
                </Col>
            </Row>
            </Fader>

            <Fader>
                <Row overrides={{marginTop:'50px'}}>
                    <Col>
                        <ContactBrum data={data}/>
                    </Col>
                </Row>
            </Fader>

            <Fader>
                <Row overrides={{marginTop:'50px'}}>
                    <Col>
                        <ContactKids data={data}/>
                    </Col>
                </Row>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'50px', marginBottom:'50px'}}>
                <Button f={() => {window.scrollTo({top:0, behavior:'smooth'})}}>Go to top</Button>
            </Row>
            </Fader>

        </GridWrapper>
    )
}
 

const ContactBrum = ({data}) => {

    let facebookLinkBrum = data.contactFacebookBrum
    let instaLinkBrum = data.contactInstagramBrum

    facebookLinkBrum = facebookLinkBrum.replace('www.', '')
    if(!facebookLinkBrum.includes('https://')) facebookLinkBrum = `https://${facebookLinkBrum}`

    instaLinkBrum = instaLinkBrum.replace('www.', '')
    if(!instaLinkBrum.includes('https://')) instaLinkBrum = `https://${instaLinkBrum}`
    
    const gotoFb = () => {
        window.open(facebookLinkBrum, '_blank')
    }

    const gotoInsta = () => {
        window.open(instaLinkBrum, '_blank')
    }

    return (
        <GridWrapper>
            <Row>
                <Typography.Header fontSize='25px'>BRUM</Typography.Header>
            </Row>
            <Row overrides={{marginTop:'40px', justifyContent:'flex-start'}}>
                <Box title={'email'} value={data.contactEmailBrum}/>
            </Row>
            <Row overrides={{marginTop:'10px', justifyContent:'flex-start'}}>
                <Box title={'instagram'} image={instaImage} onClick={() => gotoInsta()} value={''} overrides={{border:'none'}} fontColor={'white'} fontFamily={'StolzlBook'}/>
            </Row>
            <Row overrides={{marginTop:'10px', justifyContent:'flex-start'}}>
                <Box title={'facebook'} value={''} onClick={() => gotoFb()} overrides={{border:'none', backgroundColor:'#000000'}} fontColor={'white'} fontFamily={'StolzlBook'}/>
            </Row>
            <Row overrides={{marginTop:'10px', justifyContent:'flex-start'}}>
                <Box title={'mobile'} value={data.contactNumberBrum}/>
            </Row>
        </GridWrapper>
    )
}

const ContactKids = ({data}) => {

    let facebookLinkKids = data.contactFacebookKids
    let instaLinkKids = data.contactInstagramKids

    facebookLinkKids = facebookLinkKids.replace('www.', '')
    if(!facebookLinkKids.includes('https://')) facebookLinkKids = `https://${facebookLinkKids}`

    instaLinkKids = instaLinkKids.replace('www.', '')
    if(!instaLinkKids.includes('https://')) instaLinkKids = `https://${instaLinkKids}`

    const gotoFb = () => {
        window.open(facebookLinkKids, '_blank')
    }

    const gotoInsta = () => {
        window.open(instaLinkKids, '_blank')
    }

    return (
        <GridWrapper>
            <Row>
                <Col overrides={{flexDirection:'row'}}>
                    <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#5BB9A7' fontSize='30px' bold>K</TypographyTitan.Header>
                    <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#EF8DB6' fontSize='30px' bold>I</TypographyTitan.Header>
                    <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#49A4CF' fontSize='30px' bold>D</TypographyTitan.Header>
                    <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#EAB853' fontSize='30px' bold>Z</TypographyTitan.Header>
                </Col>
            </Row>
            <Row overrides={{marginTop:'40px', justifyContent:'flex-end'}}>
                <Box title={'email'} value={data.contactEmailKids} overrides={{border:'solid #889ECF 4px'}} fontColor={'#889ECF'} fontFamily={'Titan'}/>
            </Row>
            <Row overrides={{marginTop:'10px', justifyContent:'flex-end'}}>
                <Box title={'instagram'} onClick={() => gotoInsta()} value={''} overrides={{backgroundColor: '#EAB853', border:'none'}} fontColor={'white'} fontFamily={'Titan'}/>
            </Row>
            <Row overrides={{marginTop:'10px', justifyContent:'flex-end'}}>
                <Box title={'Facebook'} onClick={() => gotoFb()} value={''} overrides={{backgroundColor:'#5BB9A7', border:'none'}} fontColor={'white'} fontFamily={'Titan'}/>
            </Row>
            <Row overrides={{marginTop:'10px', justifyContent:'flex-end'}}>
                <Box title={'mobile'} value={data.contactNumberKids} overrides={{border:'solid #EF8DB6 4px'}} fontColor={'#EF8DB6'} fontFamily={'Titan'}/>
            </Row>
        </GridWrapper>
    )
}

const Box = ({title, value, image, fontFamily, fontColor, overrides, onClick}) => {

    if(image) {
        return (
            <div style={{cursor:'pointer', width: '100%', height:'130px', border:'solid #383838 4px', boxSizing:'border-box', ...overrides}} onClick={onClick}>
                <CentralizedBackgroundImage image={image}>
                    <GridWrapper overrides={{height:'100%'}}>
                        <Row overrides={{height:'100%', padding:'10px'}}>
                            <Col overrides={{justifyContent:'flex-start', alignItems:'flex-start'}}>
                                <Typography.Header fontSize='24px' color={fontColor ?? '#383838'} overrides={{fontFamily: fontFamily ?? 'StolzlBold'}}>
                                    {title}
                                </Typography.Header>
                            </Col>
                        </Row>
                        <Row overrides={{height:'100%', padding:'10px'}}>
                            <Col overrides={{justifyContent:'flex-end', alignItems:'flex-start'}}>
                                <Typography.Regular fontSize='13px' overrides={{fontFamily: 'StolzlRegular'}} color={fontColor ?? '#383838'}>
                                    {value}
                                </Typography.Regular>
                            </Col>
                        </Row>
                    </GridWrapper>
                </CentralizedBackgroundImage>
            </div>
        )
    }

    return (
        <div style={{cursor:'pointer', width: '100%', height:'130px', border:'solid #383838 4px', boxSizing:'border-box', ...overrides}} onClick={onClick}>
            <GridWrapper overrides={{height:'100%'}}>
                <Row overrides={{height:'100%', padding:'10px'}}>
                    <Col overrides={{justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <Typography.Header fontSize='24px' color={fontColor ?? '#383838'} overrides={{fontFamily: fontFamily ?? 'StolzlBold'}}>
                            {title}
                        </Typography.Header>
                    </Col>
                </Row>
                <Row overrides={{height:'100%', padding:'10px'}}>
                    <Col overrides={{justifyContent:'flex-end', alignItems:'flex-start'}}>
                        <Typography.Regular fontSize='13px' overrides={{fontFamily: 'StolzlRegular'}} color={fontColor ?? '#383838'}>
                            {value}
                        </Typography.Regular>
                    </Col>
                </Row>
            </GridWrapper>
        </div>
    )
}

export default Contact