import React, { useContext } from 'react'
import {GridWrapper, Row, Col} from '../../../../globalComponents/Grid'
import { Typography } from '../../../../globalComponents/Typography'
import {MaskedImageColumnsSmall, MaskedImageColumns, CentralizedBackgroundImage} from '../../../../globalComponents/Image'
import Nav from './Nav'
import Separator from '../../../../globalComponents/Separator'
import { ScreenTypes } from '../../../../constants/windowSize'
import { ContextManager } from '../../../../context/_index'
import Lottie from 'lottie-react'
import LottieAnim from '../../../../assets/lottie/mainHeader.json'

const Header = ({get,data}) => {

    const { screenType } = useContext(ContextManager.WindowSize)

    return(
        <GridWrapper>

            <Row>
                <Col>
                    <MaskedImageColumnsSmall
                        image={data.headerImage} 
                        height='350px'
                    />
                </Col>
            </Row>
            
            <Separator lineHeight='2px' fontSize='14.5px' text={'A new way of socialising - V1'}/>

            <Row>
                <Col width={15} overrides={{alignItems:'flex-start'}}>
                    { screenType === ScreenTypes.small_screen
                        ? <Typography.Header bold fontSize='70px'>Artsy Party.</Typography.Header>
                        : (
                            <Row>
                                <Col overrides={{alignItems:'flex-start'}}>
                                    <Typography.Header bold fontSize='70px'>Artsy</Typography.Header>
                                    <Typography.Header bold fontSize='70px'>Party.</Typography.Header>
                                </Col>
                            </Row>
                        )
                    }
                </Col>
            </Row>

            <Row overrides={{marginTop:'10px', height:'4px', backgroundColor:'#383838'}}/>

            <Row>
                <Col width={10} overrides={{alignItems:'flex-start'}}>
                    <Nav/>
                </Col>
            </Row>

            <Row overrides={{ minHeight:'100px', marginTop:'20px', borderTop:'solid #222222 2px', borderBottom:'solid #222222 2px'}}>
                <Col width={3} overrides={{alignItems:'flex-start', justifyContent:'flex-start', marginTop:'20px', marginBottom:'20px'}}>
                    <Typography.Regular fontSize={'16px'} overrides={{fontFamily:'StolzlBook'}}>
                        <div dangerouslySetInnerHTML={{ __html: data.headerText }}></div>
                    </Typography.Regular>
                </Col>
            </Row>

            <Row overrides={{marginTop:'65px'}}>
                <Col width={1}>
                    <Lottie style={{width:'150px'}} animationData={LottieAnim} loop={true}/>
                </Col>
            </Row>

        </GridWrapper>
    )
}

export default Header