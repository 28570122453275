import React, { useContext, useEffect, useState } from 'react'
import {GridWrapper, Row, Col} from '../../../globalComponents/Grid'
import Header from './Header'
import { EventsGrid } from '../EventsComponents'
import useKidsEvents from '../../../dataHooks/useKidsEvents'
import useKidsEventsPage from '../../../dataHooks/useKidsEventsPage'
import { AnimatePresence, motion } from 'framer-motion'
import Loading from '../Loading'
import { ContextManager } from '../../../context/_index'
import { ScreenTypes } from '../../../constants/windowSize'

const KidsEvents = ({}) => {

    const [ready, setReady] = useState(false)

    const {
        getKidsEvents,
        kidsEventImagesLoaded,
        kidsEventsData
    } = useKidsEvents()
    
    const {
        getKidsEventsPage,
        kidsEventsPageData,
        kidsEventsPageImagesLoaded
    } = useKidsEventsPage()

    const { screenType } = useContext(ContextManager.WindowSize)

    return (
        <GridWrapper>
            <AnimatePresence>
                {!(ready && kidsEventsData && kidsEventsPageData && kidsEventsPageImagesLoaded && kidsEventImagesLoaded) && <Loading setReady={setReady}/>}
                {(ready && kidsEventsData && kidsEventsPageData && kidsEventsPageImagesLoaded && kidsEventImagesLoaded) && 
                    <motion.div
                        key={"unique_mainPageanimation_key"}
                        exit={{ opacity:0 }}
                        initial={{ opacity:0 }}
                        animate={{ opacity:1 }}
                        transition={{duration:1, delay: 1}}
                    >
                    <GridWrapper overrides={{width:screenType === ScreenTypes.mobile ? '360px' : '450px', marginBottom:'100px'}}>

                        <Row>
                            <Col>
                                <Header text={kidsEventsPageData.text} mainImage={kidsEventsPageData.mainImage}/>
                            </Col>
                        </Row>

                        <Row overrides={{marginTop:'10px'}}>
                            <Col width={50}>
                                <EventsGrid
                                    events={kidsEventsData} 
                                />                      
                            </Col>
                        </Row>
                    </GridWrapper>
                </motion.div>}
            </AnimatePresence>
        </GridWrapper>
    )
}

export default KidsEvents