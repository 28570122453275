import { useEffect, useState } from 'react'
import {preloadImages} from '../utils/preloadImages'
import sanityClient from "@sanity/client";
import blocksToHtml from '@sanity/block-content-to-html'

// copied from sanity docs https://www.sanity.io/plugins/block-content-to-html
const h = blocksToHtml.h
const serializers = {
    types: {
      code: props => (
        h('pre', {className: props.node.language},
          h('code', props.node.code)
        )
      )
    }
}

let client = sanityClient({
  projectId: "97fmu3em",
  dataset: "production",
  useCdn: true
});

const getPageData = async () => {
    
    return await client.fetch(`
        *[_type == "kidsImagesPage"]
        {
          text, 
          "mainImage": mainImage.asset->url,
          exhibitText,
          "exhibitImage": exhibitImage.asset->url
        }`)
    .then(x => {return x[0]})
}

const useBrumImagesPage = () => {

    const [data, setData] = useState(null)
    const [imagesLoaded, setImagesLoaded] = useState(false)

    useEffect(() => {get()},[])

    const get = async () => {
        let response = await getPageData()
        preloadImages([response.mainImage, response.exhibitImage], setImagesLoaded)
        response.text = blocksToHtml({
            blocks: response?.text,
            serializers: serializers
        })
        setData(response)
    }
    
    return {
        getKidsImagesPage: get,
        kidsImagesPageData: data,
        kidsImagesPageImagesLoaded: imagesLoaded
    }
}

export default useBrumImagesPage