import React,{useState} from 'react'
import {motion} from 'framer-motion'

const Button = ({children, f, overrides}) => {

    //const [hover, setHover] = useState(false)

    return (
        <motion.div
            whileHover={{
                y:-3
            }}
            whileTap={{
                scale:0.95
            }}
            onClick={f} 
            style={{
                cursor: 'pointer',
                display:'flex',  
                justifyContent:'center',  
                alignItems:'center',  
                width:'250px',  
                height:'38px',  
                backgroundColor:'#383838',  
                color:'white',
                ...overrides
            }}
        >
            {children}
        </motion.div>
    )
}

export default Button