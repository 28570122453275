import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getStorage } from 'firebase/storage'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA2T8jhISmxCrkRacqAbRL_nGe-0-wwygc",
  authDomain: "artsyparty-v2.firebaseapp.com",
  projectId: "artsyparty-v2",
  storageBucket: "artsyparty-v2.appspot.com",
  messagingSenderId: "797124283693",
  appId: "1:797124283693:web:190f8bed2c25880284d7a4"
};

export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const storage = getStorage(app)