import {useEffect, useState} from 'react'
import {preloadImages} from '../utils/preloadImages'
import { parse, isValid } from 'date-fns'
import { enGB } from 'date-fns/locale';
import sanityClient from "@sanity/client";

let client = sanityClient({
  projectId: "97fmu3em",
  dataset: "production",
  useCdn: true
});

export const getData = async () => {
    return await client.fetch(`
        *[_type == "brumEvents"]
        {
          link,
          title,
          featured,
          "image": image.asset->url,
          date,
          location,
          price,
          description,
          time
        }`)
    .then(x => {return x})
}

const useBrumEvents = () => {
    
    const [data, setData] = useState(null)
    const [imagesLoaded, setImagesLoaded] = useState(false)

    useEffect(() => {get()},[])

    const get = async () => {
        let response = await getData()
        try{
            response.sort((a,b) => {
                a.sortDate = new Date(a.date)
                b.sortDate = new Date(b.date)
                return a.sortDate - b.sortDate
            })
        }catch(e) {
            console.log("could not sort events, are you missing a date?")
        }
        
        preloadImages([
            ...(response.map(x => {return x.image}))
        ], setImagesLoaded)
        
        setData(response)
    }

    return {
        brumEventsData: data,
        brumEventImagesLoaded: imagesLoaded,
        getBrumEvents: get
    }
}

export default useBrumEvents