import React, {useContext} from 'react'
import {GridWrapper, Row, Col} from '../../../../globalComponents/Grid'
import { Typography, TypographyTitan } from '../../../../globalComponents/Typography'
import { ContextManager } from '../../../../context/_index'

const Nav = () => {

    const {refPositions} = useContext(ContextManager.RefPosition)

    const scrollTo = (ref) => {
        ref?.current?.scrollIntoView({behavior:'smooth'})
    }

    return (
        <GridWrapper overrides={{marginTop:'15px'}}>
            
            <Row overrides={{marginTop:'18px'}}>
                <Col hoverStyles={{color:'#751482'}} clickable={() => scrollTo(refPositions.brum)} overrides={{flexDirection:'row', justifyContent:'flex-start'}}>
                    <Typography.Regular fontSize={'16px'} overrides={{fontFamily:'StolzlRegular', alignItems:'baseline'}} bold>Artsy Party </Typography.Regular>
                    <Typography.Regular fontSize={'16px'} overrides={{alignItems:'baseline', marginLeft:'5px'}}>BRUM .</Typography.Regular>
                </Col>
            </Row>

            <Row overrides={{marginTop:'18px'}}>
                <Col hoverStyles={{color:'#751482'}} clickable={() => scrollTo(refPositions.kids)} overrides={{flexDirection:'row', justifyContent:'flex-start'}}>
                    <Col overrides={{justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'row'}}>
                        <Typography.Regular fontSize={'16px'} overrides={{fontFamily:'StolzlRegular',alignItems:'baseline'}} bold>Artsy Party</Typography.Regular>
                        <TypographyTitan.Header overrides={{lineHeight:'80%', marginRight:'2px', marginLeft:'8px'}} color='#5BB9A7' fontSize='20px' bold>K</TypographyTitan.Header>
                        <TypographyTitan.Header overrides={{lineHeight:'80%', marginRight:'2px'}} color='#EF8DB6' fontSize='20px' bold>I</TypographyTitan.Header>
                        <TypographyTitan.Header overrides={{lineHeight:'80%', marginRight:'2px'}} color='#49A4CF' fontSize='20px' bold>D</TypographyTitan.Header>
                        <TypographyTitan.Header overrides={{lineHeight:'80%'}} color='#EAB853' fontSize='20px' bold>Z .</TypographyTitan.Header>
                    </Col>
                    <Typography.Regular fontSize={'16px'} overrides={{alignItems:'baseline', marginLeft:'5px'}}></Typography.Regular>
                </Col>
            </Row>

            <Row overrides={{marginTop:'18px'}}>
                <Col hoverStyles={{color:'#751482'}} clickable={() => scrollTo(refPositions.about)} overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular fontSize={'16px'} overrides={{fontFamily:'StolzlRegular'}} bold>About .</Typography.Regular>
                </Col>
            </Row>

            <Row overrides={{marginTop:'18px'}}>
                <Col hoverStyles={{color:'#751482'}} clickable={() => scrollTo(refPositions.contact)} overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular fontSize={'16px'} overrides={{fontFamily:'StolzlRegular'}} bold>Contact .</Typography.Regular>
                </Col>
            </Row>

        </GridWrapper>
    )
}

export default Nav