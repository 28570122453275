import {useEffect, useState} from 'react'
import {preloadImages} from '../utils/preloadImages'
import sanityClient from "@sanity/client";

let client = sanityClient({
  projectId: "97fmu3em",
  dataset: "production",
  useCdn: true
});

export const getData = async () => {
    let res = await client.fetch(`
        *[_type == "partners"]
        {
          name, 
          "image": image.asset->url
        }`)
    .then(x => {return x})
    return res
}

const usePartners = () => {
    
    const [data, setData] = useState(null)
    const [imagesLoaded, setImagesLoaded] = useState(false)

    useEffect(() => {get()},[])

    const get = async () => {
        let response = await getData()
        
        preloadImages([
            ...(response.map(x => {return x.image})),
        ], setImagesLoaded)
        
        setData(response)
    }
    
    return {
        partners: data,
        partnerImagesLoaded: imagesLoaded,
        getPartners: get
    }
}

export default usePartners