import React, { useState, useContext } from 'react'
import {GridWrapper, Row, Col} from '../../../globalComponents/Grid'
import { MaskedImageColumns } from '../../../globalComponents/Image'
import { Typography, TypographyTitan } from '../../../globalComponents/Typography'
import HeaderBackButton from '../../../globalComponents/HeaderBackButton'
import anim from '../../../assets/lottie/kidspageseparator.json'
import Lottie from 'lottie-react'
import { useNavigate } from 'react-router-dom'
import {motion} from 'framer-motion'
import { WindowSizeStore } from '../../../context/WindowSizeStore'
import { ScreenTypes } from '../../../constants/windowSize'

const Header = ({text, mainImage}) => {

    const {windowSize, screenType} = useContext(WindowSizeStore)

    let nav = useNavigate()
    const [hover, setHover] = useState(false)

    return(
        <GridWrapper>
            <Row>
                <Col>
                    <MaskedImageColumns
                        image={mainImage}
                        height={(windowSize.height < 700 && screenType === ScreenTypes.large_screen) ? '370px' : '500px'}
                    />
                </Col>
            </Row>

            <Row overrides={{marginTop:'10px', justifyContent:'flex-start', gap:'10px'}}>
                <Typography.Regular overrides={{whiteSpace:'nowrap', fontFamily: 'StolzlBold'}}>For the - </Typography.Regular>
                <TypographyTitan.Regular overrides={{whiteSpace:'nowrap'}} fontSize={'16px'}>Youngsters</TypographyTitan.Regular>
                <Lottie style={{width:'36px'}} animationData={anim}/>
                <div style={{width:'100%', height:'4px', backgroundColor:'#222222'}}></div>
                <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => nav('/')} style={{display:'flex', alignItems:'center', cursor: hover ? 'pointer' : 'default'}}>
                    <div>
                        <Typography.Regular>Back</Typography.Regular>
                            <motion.div style={{width:'100%', height:'2px', backgroundColor:'#222222'}} key='5j4kl325j4kl3'
                                animate={{
                                    opacity: hover ? 1 : 0
                                }}
                            />
                    </div>
                    <svg
                        style={{transform:"rotate(180deg)", marginRight:'-7px',}}
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        >
                        <path
                            d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"
                            fill="currentColor"
                        />
                    </svg>
                </div>
            </Row>

            <Row overrides={{marginTop:'15px'}}>
                <Col overrides={{alignItems:'flex-start'}}>
                    <Col overrides={{justifyContent:'flex-start', alignItems:'baseline', flexDirection:'row'}}>
                        <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#5BB9A7' fontSize='110px' bold>K</TypographyTitan.Header>
                        <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#EF8DB6' fontSize='110px' bold>i</TypographyTitan.Header>
                        <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#49A4CF' fontSize='110px' bold>d</TypographyTitan.Header>
                        <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#EAB853' fontSize='110px' bold>z</TypographyTitan.Header>
                        <TypographyTitan.Header bold overrides={{lineHeight:'70%', marginLeft:'20px'}}>Events.</TypographyTitan.Header>
                    </Col>
                </Col>
            </Row>

            <Row overrides={{marginTop:'60px'}}>
                <Col>
                    <div style={{width:'100%', height:'4px', backgroundColor:'#222222'}}></div>
                </Col>
            </Row>

            <Row overrides={{marginTop:'30px'}}>
                <Col width={2} overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular overrides={{fontFamily:'StolzlBook'}}>
                        <div dangerouslySetInnerHTML={{ __html: text }}></div>
                    </Typography.Regular>
                </Col>
                <Col width={1}/>
            </Row>

        </GridWrapper>
    )
}

export default Header