import React, { useRef, useEffect, useContext} from 'react'
import { ContextManager } from '../../../../context/_index'
import {GridWrapper, Row, Col} from '../../../../globalComponents/Grid'
import { Typography, TypographyTitan } from '../../../../globalComponents/Typography'
import {EventsList} from '../../EventsComponents'
import ForTheYoungstersImage from '../../../../assets/images/animatedBackgroundImage.jpg'
import { motion } from 'framer-motion'
import ImageDisplay from '../ImageDisplay'
import Separator from '../../../../globalComponents/Separator'
import Fader from '../../../../globalComponents/Fader'
import ButtonBackground from '../../../../assets/images/kidsButtonBackground.jpg'
import Lottie from 'lottie-react'
import LottieAnim from '../../../../assets/lottie/upcomingkids.json'

import circleSVG from '../../../../assets/images/circle.svg'
import { Link } from 'react-router-dom'

const Kids = ({setRef, pageData, imagesData, eventsData, get}) => {

    const {refPositions} = useContext(ContextManager.RefPosition)
    const scrollToContact = () => {
        refPositions?.contact?.current?.scrollIntoView({behavior:'smooth'})
    }

    const ref = useRef()
    useEffect(() => {setRef(ref)},[])


    let animWrapStyle = {
        width: `1000px`,
        height: `900px`,
        backgroundImage: `url(${pageData.kidsImage})`,
        backgroundSize:`cover`,
        maskImage: `url(${circleSVG})`,
        webkitMaskImage: `url(${circleSVG})`,
        maskSize: `300px, 200px`,
        webkitMaskSize: `300px, 200px`,
        maskRepeat: `no-repeat`,
        webkitMaskRepeat: `no-repeat`,
        maskPosition: `center`,
        webkitMaskPosition: `center`,
        maskComposite: `exclude`,
        webkitMaskComposite: `add`
    }

    return(
        <GridWrapper overrides={{marginTop:'70px', maxWidth:'100%', overflow:'hidden', borderTop:'solid #222222 4px', paddingLeft:'5px'}}>
            <Fader>
                <TypographyTitan.Header fontSize='50px' overrides={{
                    marginTop:'50px',
                    backgroundImage:`url(${ForTheYoungstersImage})`,
                    backgroundSize:'cover',
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    color:'transparent'
                }}>
                    Something For The Youngsters.
                </TypographyTitan.Header>
                <Typography.Header fontSize='50px' overrides={{marginTop:'50px'}} bold>Art Can Make The Mind Grow.</Typography.Header>
            </Fader>

            <Fader>
                <Row overrides={{borderBottom:'solid #222222 4px', marginTop:'80px'}}/>
            </Fader>

            <Fader>
            <Row overrides={{height:'600px',  marginTop:'100px', paddingBottom:'100px'}}>
                <Col>
                    <motion.div key='h5342iuqopht4'
                        style={animWrapStyle}
                        animate={{
                            maskPosition:      ['50% 50%',    '80% 60%',   '70% 20%',      '10% 10%',      '10% 80%',      '30% 60%',      '90% 10%',      '50% 50%',    '50% 50%',       '50% 50%',       '50% 50%',       '50% 50%'],
                            WebkitMaskPosition:['50% 50%',    '80% 60%',   '70% 20%',      '10% 10%',      '10% 80%',      '30% 60%',      '90% 10%',      '50% 50%',    '50% 50%',       '50% 50%',       '50% 50%',       '50% 50%'],
                            maskSize:          ['50px, 50px', `90px, 90px`,'400px, 400px', '120px, 120px', '500px, 500px', '100px, 100px', '300px, 300px', '50px, 50px', '1200px, 900px', '1200px, 900px', '1200px, 900px', '50px, 50px'],
                            WebkitMaskSize:    ['50px, 50px', `90px, 90px`,'400px, 400px', '120px, 120px', '500px, 500px', '100px, 100px', '300px, 300px', '50px, 50px', '1200px, 900px', '1200px, 900px', '1200px, 900px', '50px, 50px'],
                        }}
                        transition={{ 
                            repeat: Infinity, 
                            duration: parseInt(pageData.animationDuration)
                        }}
                    >
                    </motion.div>
                </Col>
            </Row>
            </Fader>


            <motion.div
                initial={{ opacity:0 }}
                animate={{ opacity:1 }}
                viewport={{ once: true }}
                transition={{ duration: 3 }}
            >
                <Fader>
                <div style={{width:'100%'}} ref={ref}>
                    <Separator text={'Artsy Party Kidz C.I.C'}/>
                </div>
                </Fader>

                <Fader>
                <Row overrides={{alignItems:'flex-start', marginTop:'20px'}}>
                    <Col width={10}>
                        <Link to='/imagegallery/kids' style={{width:'100%', textDecoration:'none'}}>
                            <ImageDisplay images={imagesData}/>
                            <div style={{marginTop:'-10px', width:'100%'}}>
                                <Separator fontFamily={'StolzlMedium'} text={'View Gallery'} isLink linkTo='/imagegallery/kids' bold={false} lineHeight={'2px'} includeLottie/>
                            </div>
                        </Link>
                    </Col>
                    <Col width={1}/>
                    <Col width={10} overrides={{alignItems:'flex-start'}}>
                        <Row>
                            <Col overrides={{justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'row'}}>
                                <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#5BB9A7' fontSize='170px' bold>K</TypographyTitan.Header>
                                <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#EF8DB6' fontSize='170px' bold>I</TypographyTitan.Header>
                                <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#49A4CF' fontSize='170px' bold>D</TypographyTitan.Header>
                                <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#EAB853' fontSize='170px' bold>Z</TypographyTitan.Header>
                            </Col>
                        </Row>
                        <Row overrides={{marginTop:'20px'}}>
                            <Col overrides={{alignItems:'flex-start'}}>
                                <Typography.Regular overrides={{fontFamily:'StolzlBook', marginTop:'25px'}}>
                                    <div dangerouslySetInnerHTML={{ __html: pageData.kidsText }}></div>
                                </Typography.Regular>
                                <motion.div
                                    whileHover={{
                                        y:-3
                                    }}
                                    whileTap={{
                                        scale:0.95
                                    }}
                                    onClick={() => scrollToContact()} 
                                    style={{
                                        marginTop:'20px',
                                        display:'flex', 
                                        alignItems:'center', 
                                        justifyContent:'center', 
                                        background: `url(${ButtonBackground})`, 
                                        backgroundSize:'cover',
                                        backgroundPosition:'center',
                                        height:'44px', 
                                        width:'220px', 
                                        borderRadius:'5px', 
                                        color:'white',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Get in touch
                                </motion.div>
                            </Col>
                        </Row>
                    </Col>
                    
                </Row>
                </Fader>

                <Fader>
                <Row overrides={{marginTop:'100px'}}>
                    <Col overrides={{alignItems:'flex-start'}}>
                        <Row>
                            <Col width={1} overrides={{alignItems:'flex-start'}}>
                                <TypographyTitan.Header fontSize='63px' overrides={{letterSpacing:'2px'}} bold>Upcoming</TypographyTitan.Header>
                            </Col>
                            <Col width={2} overrides={{alignItems:'flex-start', marginLeft:'20px'}}>
                                <Lottie style={{width:'70px'}} animationData={LottieAnim}/>
                            </Col>
                        </Row>
                        <TypographyTitan.Header overrides={{marginTop:'10px', letterSpacing:'2px'}} fontSize='63px' bold>Events</TypographyTitan.Header>
                    </Col>
                </Row>
                </Fader>

                <Fader>
                    <EventsList events={eventsData} linkTo={'/events/kids'}/>
                </Fader>
            </motion.div>

        </GridWrapper>
    )
}

export default Kids