import React, {useContext, useState} from 'react'
import {GridWrapper, Row, Col} from '../../../../globalComponents/Grid'
import { Typography, TypographyTitan } from '../../../../globalComponents/Typography'
import { ContextManager } from '../../../../context/_index'
import { motion } from 'framer-motion'

const Nav = () => {

    const {refPositions} = useContext(ContextManager.RefPosition)

    const [hover1, setHover1] = useState(false)
    const [hover2, setHover2] = useState(false)
    const [hover3, setHover3] = useState(false)
    const [hover4, setHover4] = useState(false)

    const scrollTo = (ref) => {
        ref?.current?.scrollIntoView({behavior:'smooth'})
    }

    return (
        <GridWrapper overrides={{marginTop:'15px'}}>

            <Row>
                <Col hoverStyles={{color:'#751482'}} clickable={() => scrollTo(refPositions.brum)} overrides={{flexDirection:'row', justifyContent:'flex-start'}}>
                    <div onMouseEnter={() => setHover1(true)} onMouseLeave={() => setHover1(false)} style={{flexDirection:'column',display:'flex', alignItems:'center', cursor: hover1 ? 'pointer' : 'default'}}>
                        <Typography.Regular fontSize={'16px'} overrides={{fontFamily:'StolzlBold', alignItems:'baseline'}} bold>Artsy Party BRUM</Typography.Regular>
                            <motion.div style={{width:'100%', height:'2px', backgroundColor:'#222222'}} key='5j4kl325j4kl3'
                                animate={{
                                    opacity: hover1 ? 1 : 0
                                }}
                            />
                    </div>
                </Col>
                <Col hoverStyles={{color:'#751482'}} clickable={() => scrollTo(refPositions.kids)} overrides={{marginLeft:'10px',flexDirection:'row', justifyContent:'flex-start'}}>
                    <div onMouseEnter={() => setHover2(true)} onMouseLeave={() => setHover2(false)} style={{flexDirection:'column', display:'flex', cursor: hover2 ? 'pointer' : 'default'}}>
                        <Col overrides={{justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'row'}}>
                            <Typography.Regular fontSize={'16px'} overrides={{fontFamily:'StolzlBold',alignItems:'baseline'}} bold>Artsy Party</Typography.Regular>
                            <TypographyTitan.Header overrides={{lineHeight:'80%', marginRight:'2px', marginLeft:'8px'}} color='#5BB9A7' fontSize='20px' bold>K</TypographyTitan.Header>
                            <TypographyTitan.Header overrides={{lineHeight:'80%', marginRight:'2px'}} color='#EF8DB6' fontSize='20px' bold>I</TypographyTitan.Header>
                            <TypographyTitan.Header overrides={{lineHeight:'80%', marginRight:'2px'}} color='#49A4CF' fontSize='20px' bold>D</TypographyTitan.Header>
                            <TypographyTitan.Header overrides={{lineHeight:'80%'}} color='#EAB853' fontSize='20px' bold>Z</TypographyTitan.Header>
                        </Col>
                        <motion.div style={{width:'100%', height:'2px', backgroundColor:'#222222'}} key='5j4kl325j4kl3'
                                animate={{
                                    opacity: hover2 ? 1 : 0
                                }}
                            />
                    </div>
                </Col>
                
            </Row>

            <Row overrides={{marginTop:'18px'}}>
                <Col hoverStyles={{color:'#751482'}} clickable={() => scrollTo(refPositions.about)} overrides={{alignItems:'flex-start'}}>
                    <div onMouseEnter={() => setHover3(true)} onMouseLeave={() => setHover3(false)} style={{flexDirection:'column', display:'flex', cursor: hover3 ? 'pointer' : 'default'}}>
                        <Typography.Regular fontSize={'16px'} overrides={{fontFamily:'StolzlBold'}} bold>About</Typography.Regular>
                        <motion.div style={{width:'100%', height:'2px', backgroundColor:'#222222'}} key='5j4kl325j4kl3'
                                animate={{
                                    opacity: hover3 ? 1 : 0
                                }}
                            />
                    </div>
                </Col>
                <Col width={1}/>
                <Col hoverStyles={{color:'#751482'}} clickable={() => scrollTo(refPositions.contact)} overrides={{marginLeft:'10px', alignItems:'flex-start'}}>
                    <div onMouseEnter={() => setHover4(true)} onMouseLeave={() => setHover4(false)} style={{flexDirection:'column', display:'flex', cursor: hover4 ? 'pointer' : 'default'}}>
                        <Typography.Regular fontSize={'16px'} overrides={{fontFamily:'StolzlBold'}} bold>Contact</Typography.Regular>
                        <motion.div style={{width:'100%', height:'2px', backgroundColor:'#222222'}} key='5j4kl325j4kl3'
                                animate={{
                                    opacity: hover4 ? 1 : 0
                                }}
                            />
                    </div>
                </Col>
            </Row>

        </GridWrapper>
    )
}

export default Nav