import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { GridWrapper, Row, Col } from '../../globalComponents/Grid'
import { ImageV2, CentralizedBackgroundImage } from '../../globalComponents/Image'
import { Typography } from '../../globalComponents/Typography'
import Arrow from '../../assets/images/arrow.png'
import Lottie from 'lottie-react'
import LottieAnim from '../../assets/lottie/viewevents.json'
import Fader from '../../globalComponents/Fader'
import {motion} from 'framer-motion'

import {format, parseISO } from 'date-fns'

const getFeatured = (events) => {
    return events.filter(x => x.featured === true).sort((a,b) => {
        a.sortDate = new Date(a.date)
        b.sortDate = new Date(b.date)
        return b.sortDate - a.sortDate
    })
}
const getNonFeatured = (events) => {
    return events.filter(x => x.featured !== true).sort((a,b) => {
        a.sortDate = new Date(a.date)
        b.sortDate = new Date(b.date)
        return b.sortDate - a.sortDate
    })
}

export const EventsList = ({events, linkTo}) => {
    
    const [hover, setHover] = useState(false)

    if(getFeatured(events)?.length < 3) {
        return (
            <GridWrapper overrides={{marginTop:'90px'}}>
                <Row overrides={{alignItems:'center'}}>
                    <div style={{width:'100%', overflowY:'scroll', paddingTop:'10px'}}>
                        {getFeatured(events)?.slice(0, 3).map((event) => {
                            return <div style={{marginRight:'40px', float:'left'}}>
                                <Event key={event.image} event={event}/>
                            </div>
                        })}    
                    </div>
                </Row>
                <Row overrides={{marginTop:'30px', justifyContent:'flex-start'}}>
                    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{display:'flex', flexDirection:'row', alignItems:'center',cursor:'pointer'}}>
                        <Link style={{textDecoration:'none', color:'black'}} to={linkTo}>
                            <Typography.Regular overrides={{display:'flex', alignItems:'center', flexDirection:'row', fontFamily:'StolzlRegular'}}>
                                View All Events 
                            </Typography.Regular>
                            <motion.div style={{width:'100%', height:'2px', backgroundColor:'#222222'}} key='5j4kl325j4kl3'
                                animate={{
                                    opacity: hover ? 1 : 0
                                }}
                            />
                        </Link>
                        <ImageV2 overrides={{width:'25px', marginLeft:'10px'}} image={Arrow}/>
                        <Lottie style={{marginLeft:'20px', width:'40px'}} animationData={LottieAnim}/>
                    </div>
                </Row>
            </GridWrapper>
        )
    }

    return(
        <GridWrapper overrides={{marginTop:'90px'}}>
            <Row overrides={{alignItems:'center'}}>
                <div style={{display:'flex', justifyContent:'space-between', width:'100%', overflowY:'scroll', paddingTop:'10px'}}>
                    {getFeatured(events)?.slice(0, 3).map((event) => {
                        return <Event key={event.image} event={event}/>
                    })}    
                </div>
            </Row>
            <Row overrides={{marginTop:'30px', justifyContent:'flex-start'}}>
                <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{display:'flex', flexDirection:'row', alignItems:'center',cursor:'pointer'}}>
                    <Link style={{textDecoration:'none', color:'black'}} to={linkTo}>
                        <Typography.Regular overrides={{display:'flex', alignItems:'center', flexDirection:'row', fontFamily:'StolzlRegular'}}>
                            View All Events 
                        </Typography.Regular>
                        <motion.div style={{width:'100%', height:'2px', backgroundColor:'#222222'}} key='5j4kl325j4kl3'
                            animate={{
                                opacity: hover ? 1 : 0
                            }}
                        />
                    </Link>
                    <ImageV2 overrides={{width:'25px', marginLeft:'10px'}} image={Arrow}/>
                    <Lottie style={{marginLeft:'20px', width:'40px'}} animationData={LottieAnim}/>
                </div>
            </Row>
        </GridWrapper>
    )
}

export const EventsGrid = ({events}) => {

    let featuredRows = []
    let nonFeaturedRows = []
    let size = 3

    let featured = getFeatured(events)
    let nonFeatured = getNonFeatured(events)

    //split into rows of size
    for (let i = 0; i < featured.length; i += size) featuredRows.push(featured.slice(i, i + size))
    for (let i = 0; i < nonFeatured.length; i += size) nonFeaturedRows.push(nonFeatured.slice(i, i + size))

    //if the last row has < size. Add null elements
    if(featuredRows.length > 0)
        while(featuredRows[featuredRows.length - 1].length < size) 
            featuredRows[featuredRows.length - 1].push(null)

    if(nonFeaturedRows.length > 0)
        while(nonFeaturedRows[nonFeaturedRows.length - 1].length < size) 
            nonFeaturedRows[nonFeaturedRows.length - 1].push(null)

    return (
        <GridWrapper>
            <Row overrides={{justifyContent:'flex-start'}}><Typography.Header fontSize='35px'>Featured</Typography.Header></Row>
            {featuredRows.map((row, rowIndex) => {
                return (
                    <Fader key={`eventRow${rowIndex}`} >
                    <Row overrides={{justifyContent:'space-between', marginTop:'50px'}}>
                        {row.map((col, colIndex) => {
                            if(!col) return <Col></Col>
                            return <Col overrides={{alignItems:'flex-start'}}>
                                <Event 
                                    key={`eventCol${rowIndex}${colIndex}`} 
                                    event={col}
                                />
                            </Col>
                        })}        
                    </Row>
                    </Fader>
                )
            })}
            <Row overrides={{marginTop:'100px', justifyContent:'flex-start'}}><Typography.Header fontSize='35px'>Previous Events</Typography.Header></Row>
            {nonFeaturedRows.map((row, rowIndex) => {
                return (
                    <Fader key={`eventRow${rowIndex}`} >
                    <Row overrides={{justifyContent:'space-between', marginTop:'50px'}}>
                        {row.map((col, colIndex) => {
                            if(!col) return <Col></Col>
                            return <Col overrides={{alignItems:'flex-start'}}>
                                <Event 
                                    key={`eventCol${rowIndex}${colIndex}`} 
                                    event={col}
                                />
                            </Col>
                        })}        
                    </Row>
                    </Fader>
                )
            })}
        </GridWrapper>
    )
}

export const Event = ({event}) => {

    const img = event.image
    let link = event?.link

    link = link?.replace('www.', '')
    if(!link?.includes('https://')) link = `https://${link}`

    if(event === null) return <div></div>

    const genColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const isFree = (v) => {
        let v2 = Number(v)
        if(isNaN(v2)) return ''
        return '£'
    }

    return (
        <motion.div
            whileHover={{
                y:-3
            }}
            whileTap={{
                scale:0.95
            }}
            onClick={() => {
                if(event.poster){
                    window.open(event.poster, '_blank')
                }else if (link){
                    window.open(link, '_blank')
                }
            }} 
            style={{width:'310px',minWidth:'310px', border:'solid #383838 4px', cursor: 'pointer'}}>
                <GridWrapper>
                    <Row overrides={{justifyContent:'flex-end', height:'6px'}}>
                        <div style={{height:'100%', width:'13px', backgroundColor:genColor()}}></div>
                        <div style={{height:'100%', width:'13px', backgroundColor:genColor()}}></div>
                        <div style={{height:'100%', width:'13px', backgroundColor:genColor()}}></div>
                    </Row>
                    <Row overrides={{padding:'10px', borderBottom:'solid #383838 2px', height:'120px'}}>
                        <Col overrides={{height:'100%', alignItems:'flex-start'}}>
                            <div style={{width:'100%', justifySelf:'flex-start'}}>
                                <Typography.Regular bold fontSize={'19px'}>{event.title}</Typography.Regular>
                            </div>
                            <div style={{height:'100%', display:'flex', alignItems:'flex-end'}}>
                                <Typography.Regular overrides={{fontFamily:'StolzlBook'}}>{format(parseISO(event.date), 'dd/MM/yyyy')}</Typography.Regular>
                            </div>
                        </Col>
                    </Row>
                    <Row overrides={{height:'100px', borderBottom:'solid #383838 2px'}}>
                        <Col width={1} overrides={{borderRight:'solid #383838 2px'}}>
                            <Typography.Regular bold 
                                fontSize={event.price === 0 || !event.price ? '18px' : '25px'}
                            >
                                {isFree(event?.price)}{event.price === 0 || !event.price ? 'Free' : event.price}
                            </Typography.Regular>
                        </Col>
                        <Col width={2} overrides={{alignItems:'flex-start', padding:'10px'}}>
                            <Typography.Regular overrides={{overflow:'hidden'}}>{event.location}</Typography.Regular>
                            <Typography.Regular overrides={{overflow:'hidden', marginTop:'10px', fontFamily:'StolzlBook'}}>{event.time}</Typography.Regular>
                        </Col>
                    </Row>
                    <Row overrides={{overflow:'hidden', padding:'20px', height:'170px'}}>
                        <Typography.Regular applywebkitbox overrides={{
                            fontFamily:'StolzlBook', 
                            height:'103%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box !important',
                            WebkitLineClamp: 7,
                            WebkitBoxOrient: 'vertical',
                            whiteSpace: 'normal'
                        }}>
                                {event.description}
                        </Typography.Regular>
                    </Row>
                    <Row overrides={{padding:'20px', paddingTop:'0px', height:'220px'}}>
                        <Col>
                            <CentralizedBackgroundImage image={img} print={true}/>
                        </Col>
                    </Row>
                    
                </GridWrapper>
        </motion.div>
    )
}