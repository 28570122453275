import { GridWrapper, Row, Col } from "../../../globalComponents/Grid"
import { MaskedImageColumnsSmall } from "../../../globalComponents/Image"
import Separator from "../../../globalComponents/Separator"
import { Typography } from "../../../globalComponents/Typography"
import {HeaderBackButtonSmall} from '../../../globalComponents/HeaderBackButton'
import anim from '../../../assets/lottie/brumpageseparator.json'
import Lottie from 'lottie-react'
import {motion} from 'framer-motion'
import { useNavigate } from "react-router-dom"
import { useState } from "react"

const Header = ({data}) => {

    let nav = useNavigate()
    const [hover, setHover] = useState(false)

    return (
        <GridWrapper>
            <Row>
                <Col>
                    <MaskedImageColumnsSmall
                        image={data.mainImage} 
                        height='400px'
                    />
                </Col>
            </Row>

            <Row overrides={{marginTop:'10px', justifyContent:'flex-start', gap:'10px'}}>
                <Typography.Regular fontSize={'19px'} overrides={{whiteSpace:'nowrap', fontFamily: 'StolzlBold'}}>A new way of socialising</Typography.Regular>
                <Lottie animationData={anim} style={{height:'30px', width:'30px', minWidth:'30px'}}/>
                <div style={{width:'100%', height:'3px', backgroundColor:'#222222'}}></div>
            </Row>

            <Row overrides={{marginTop:'10px'}}>
                <Col overrides={{alignItems:'flex-start'}}>
                    <Typography.Header bold fontSize='75px'>Brum</Typography.Header>
                    <Typography.Header bold fontSize='75px'>Gallery.</Typography.Header>
                </Col>
            </Row>

            <Row overrides={{marginTop:'20px'}}>
                <Col>
                    <Row overrides={{gap:'10px'}}>
                        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => nav('/')} style={{display:'flex', alignItems:'center', cursor: hover ? 'pointer' : 'default'}}>
                            <div>
                                <Typography.Regular fontSize={'19px'}>Back</Typography.Regular>
                                    <motion.div style={{width:'100%', height:'2px', backgroundColor:'#222222'}} key='5j4kl325j4kl3'
                                        animate={{
                                            opacity: hover ? 1 : 0
                                        }}
                                    />
                            </div>
                            <svg
                                style={{transform:"rotate(180deg)", marginRight:'-7px',}}
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                >
                                <path
                                    d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <div style={{width:'100%', height:'4px', backgroundColor:'#222222'}}></div>
                    </Row>
                </Col>
            </Row>

            <Row overrides={{marginTop:'30px'}}>
                <Col width={2} overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular overrides={{fontFamily:'StolzlBook'}} fontSize={'17px'}>
                        <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
                    </Typography.Regular>
                </Col>
            </Row>

        </GridWrapper>
    )
}

export default Header