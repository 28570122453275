import React from 'react'
import { GridWrapper, Row, Col } from '../../../../globalComponents/Grid'
import Fader from '../../../../globalComponents/Fader'
import { Typography, TypographyTitan } from '../../../../globalComponents/Typography'
import Lottie from 'lottie-react'
import LottieAnim from '../../../../assets/lottie/upcoming.json'
import { CentralizedBackgroundImage } from '../../../../globalComponents/Image'
import backgroundPlaceholder from '../../../../assets/images/backgroundvideoplaceholder.png'
import Button from '../../../../globalComponents/Button'

const WhatPeopleThink = ({data, testimonials}) => {
    return (
        <GridWrapper>
            <Fader>
                <Row>
                    <Header/>
                </Row>
            </Fader>
            <Fader>
                <Row>
                    <Testimonials testimonials={testimonials}/>
                </Row>
            </Fader>
            <Fader>
                <Row>
                    <LeaveReview data={data}/>
                </Row>
            </Fader>
        </GridWrapper>
    )
}

const Header = () => {
    return (
        <GridWrapper>
            <Fader>
            <Row overrides={{marginTop:'100px'}}>
                <Col overrides={{alignItems:'flex-start'}}>
                    <Row>
                        <Col width={1} overrides={{alignItems:'flex-start'}}>
                            <Typography.Header fontSize='60px' bold>What The</Typography.Header>
                        </Col>
                        <Col width={2} overrides={{alignItems:'flex-start'}}>
                            <Lottie style={{width:'80px', marginLeft:'10px'}} animationData={LottieAnim}/>
                        </Col>
                    </Row>
                    <Typography.Header overrides={{marginTop:'-5px'}} fontSize='60px' bold>People Think</Typography.Header>
                </Col>
            </Row>
            </Fader>
        </GridWrapper>
    )
}

const Testimonials = ({testimonials}) => {

    let t1 = testimonials[0]
    let t2 = testimonials[1]
    let t3 = testimonials[2]

    return (
        <GridWrapper overrides={{marginTop:'120px'}}>
            <Row>
                <Col overrides={{justifyContent:'flex-start', alignItems:'flex-start', height:'500px'}}>
                    <div style={{width:'85%'}}>
                        {t1 && <TestimonialCard
                            image={t1.image}
                            name={t1.name}
                            title={t1.title}
                            description={t1.description}
                        />}
                    </div>
                </Col>
                <Col overrides={{justifyContent:'flex-start', height:'500px'}}>
                    <div style={{width:'85%'}}>
                        {t2 && <TestimonialCard
                            image={t2.image}
                            name={t2.name}
                            title={t2.title}
                            description={t2.description}
                        />}
                    </div>
                </Col>
                <Col overrides={{justifyContent:'flex-start', alignItems: 'flex-end', height:'500px'}}>
                    <div style={{width:'85%'}}>
                        {t3 && <TestimonialCard
                            image={t3.image}
                            name={t3.name}
                            title={t3.title}
                            description={t3.description}
                        />}
                    </div>
                </Col>
            </Row>
        </GridWrapper>
    )
}

const TestimonialCard = ({image, name, title, description}) => {
    
    return (
        <GridWrapper>
            <Row overrides={{height:'220px'}}>
                <Col>
                    <CentralizedBackgroundImage image={image}/>
                </Col>
            </Row>
            <Row overrides={{marginTop:'20px', height:'70px'}}>
                <Col overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular bold fontSize={'25px'}>{name}</Typography.Regular>
                </Col>
            </Row>
            <Row>
                <Col overrides={{alignItems:'flex-start', height:'40px'}}>
                    <Typography.Regular overrides={{fontFamily:'StolzlRegular'}} fontSize={'15px'}>{title}</Typography.Regular>
                </Col>
            </Row>
            <Row overrides={{borderTop:'solid #383838 2px', borderBottom:'solid #383838 2px', height:'150px', marginTop:'20px'}}>
                <Col width={3} overrides={{marginLeft:'10px'}}>
                    <Typography.Regular overrides={{fontFamily:'StolzlBook'}} fontSize={'14px'}>{description}</Typography.Regular>
                </Col>
            </Row>
        </GridWrapper>
    )
}

const LeaveReview = ({data}) => {
    
    const openReviews = () => {
        window.open(data.reviewsLink, '_blank')
    }

    return (
        <GridWrapper>
            <Row overrides={{backgroundColor:'black', paddingTop:'15px', paddingBottom:'15px', height:'700px', marginTop:'100px'}}>
                <Col>
                    <CentralizedBackgroundImage image={backgroundPlaceholder}>
                        <GridWrapper overrides={{height:'100%'}}>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <TypographyTitan.Header fontSize='50px'>
                                                Leave A Review
                                            </TypographyTitan.Header>
                                        </Col>
                                    </Row>
                                    <Row overrides={{marginTop:'20px'}}>
                                        <Col>
                                            <div style={{width:'400px'}}>
                                                <Typography.Regular overrides={{fontFamily:'StolzlBook', textAlign:'center'}} fontSize={'15px'}>
                                                    {data.leaveReviewText}
                                                </Typography.Regular>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row overrides={{marginTop:'20px'}}>
                                        <Col>
                                            <Button f={() => openReviews()} overrides={{height:'30px', width:'140px'}}>
                                                <Typography.Regular fontSize={'12px'} color={'white'} overrides={{fontFamily:'StolzlBook'}}>
                                                    Reviews
                                                </Typography.Regular>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </GridWrapper>
                    </CentralizedBackgroundImage>
                </Col>
            </Row>
        </GridWrapper>
    )
}

export default WhatPeopleThink