import React, {useState, useEffect } from 'react'
import { ImageV2 } from '../../globalComponents/Image';
import Logo from '../../assets/images/logo.png'
import Lottie from 'lottie-react'
import LottieAnim from '../../assets/lottie/landing.json'
import { motion } from 'framer-motion'

const Loading = ({loadPercent, setReady}) => {

    useEffect(() => {
        const timer = setInterval(() => {
            setReady(true)
          }, 2000);
          return () => clearTimeout(timer);
    }, [])

    return (
        <motion.div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', height:'100vh'}}
            key={"my_unique_loader_key"}
            exit={{ opacity:0 }}
            initial={{ opacity:0 }}
            animate={{ opacity:1 }}
            transition={{duration:1}}
        >
            <div style={{height:'150px', width:'150px', marginLeft:'15px'}}>
                <Lottie animationData={LottieAnim} loop={true}/>
            </div>
            {/* <TypographyTitan.Regular overrides={{fontFamily:'StolzlBold', marginTop:'20px'}} fontSize={'85px'} bold>ARTSY PARTY</TypographyTitan.Regular> */}
            {/* <TypographyTitan.Regular overrides={{fontFamily:'StolzlRegular', marginTop:'20px', marginLeft:'10px'}} fontSize={'85px'}>{loadPercent}%</TypographyTitan.Regular> */}
            {/* {loadPercent &&<TypographyTitan.Regular overrides={{fontFamily:'StolzlRegular', marginTop:'20px'}} fontSize={'15px'} bold>Loading : {loadPercent}%</TypographyTitan.Regular>} */}
        </motion.div>
    )
}

export default Loading