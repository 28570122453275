import React,{useState, useRef} from 'react'
import Button from '../../../globalComponents/Button'
import { GridWrapper, Row, Col } from '../../../globalComponents/Grid'
import { ImageV2, CentralizedBackgroundImage } from '../../../globalComponents/Image'
import { Typography } from '../../../globalComponents/Typography'
import Arrow from '../../../assets/images/arrow.png'
import Exhibit from '../Exhibit'
import Header from './Header'
import Images from '../Images'
import useKidsImagesPage from '../../../dataHooks/useKidsImagesPage'
import {motion, AnimatePresence, useViewportScroll} from 'framer-motion'
import Loading from '../Loading'
import Fader from '../../../globalComponents/Fader'
import useKidsImageGallery from '../../../dataHooks/useKidsGallery'
import { useEffect } from 'react'

const KidsImageGallery = () => {
    const ref = useRef()
    const exhibitScrollProgress = useViewportScroll(ref)

    const [stickExhibit, setStickExhibit] = useState(false)

    useEffect(() => {
        exhibitScrollProgress.scrollYProgress.onChange((x) => {
            if(ref?.current?.getBoundingClientRect()){
                let bound = ref.current.getBoundingClientRect()
                if(bound?.y < 21) setStickExhibit(true)
                if(bound?.y > 21) setStickExhibit(false)
            }
        })
    },[exhibitScrollProgress])

    const [ready, setReady] = useState(false)
    const [showControls, setShowControls] = useState(false)

    const { 
        kidsImagesPageData,
        getKidsImagesPage,
        kidsImagesPageImagesLoaded
    } = useKidsImagesPage()

    const {
        kidsImages,
        getKidsImages,
        kidsImagesLoaded,
        onNext,
        onPrev,
        openImage,
        closeImage,
        modalOpen,
        itemInView
    } = useKidsImageGallery()

    return (
        <GridWrapper>
            <AnimatePresence>
            {!(ready && kidsImages && kidsImagesLoaded && kidsImagesPageData && kidsImagesPageImagesLoaded) && <Loading setReady={setReady}/>}
            {(ready && kidsImages && kidsImagesLoaded && kidsImagesPageData && kidsImagesPageImagesLoaded) && 
                <motion.div
                    key={"unique_mainPageanimation_key"}
                    exit={{ opacity:0 }}
                    initial={{ opacity:0 }}
                    animate={{ opacity:1 }}
                    transition={{duration:1, delay: 1}}
                >
                    <div onMouseEnter={() => setShowControls(true)} onMouseLeave={() => setShowControls(false)} onClick={(e) => e.stopPropagation()} style={{
                           display: modalOpen ? 'inline-block' : 'none', 
                           position:'fixed',
                           zIndex:'1', 
                           left: '50%',
                           top: '50%',
                           transform: 'translate(-50%, -50%)',
                           width:'100%', 
                           maxWidth:'1250px',
                           height:'95vh', 
                           overflow:'hidden', 
                           backgroundColor:'black',
                           padding:'10px'
                        }} 
                    >
                        <CentralizedBackgroundImage image={kidsImages[itemInView].image} overrides={{backgroundSize:'contain', backgroundRepeat:'no-repeat'}}>
                            <GridWrapper overrides={{height:'100%', justifyContent:'flex-start'}}>
                                <Row overrides={{height:'35px', marginTop:'20px', paddingLeft:'20px', paddingRight:'20px'}}>
                                    <Col overrides={{alignItems:'flex-end', flexDirection:'row'}}>
                                        <Col width={25} overrides={{alignItems:'flex-start'}}>
                                            { showControls &&
                                                <Button f={(e) => closeImage()} overrides={{marginRight:'8px', width:'40px', backgroundColor:'#D9D9D9'}}>
                                                    <Typography.Regular bold fontSize={'12px'} overrides={{color:'#000000'}}>X</Typography.Regular>
                                                </Button>
                                            }
                                        </Col>
                                        <Col width={3}>
                                            { showControls &&
                                                <Button f={() => onPrev()} overrides={{marginRight:'8px', width:'100%', backgroundColor:'#D9D9D9'}}>
                                                    <Typography.Regular bold fontSize={'12px'} overrides={{color:'#000000'}}>
                                                        <Row>
                                                            <ImageV2 overrides={{width:'15px', height:'7px', marginRight:'10px', transform:'rotate(180deg)'}} image={Arrow}/> Prev.
                                                        </Row>
                                                    </Typography.Regular>
                                                </Button>
                                            }
                                        </Col>
                                        <Col width={3}>
                                                { showControls &&
                                                    <Button f={() => onNext()} overrides={{width:'100%', backgroundColor:'#D9D9D9'}} bold>
                                                        <Typography.Regular bold fontSize={'12px'} overrides={{color:'#000000'}}>
                                                            <Row>
                                                                Next. 
                                                                <ImageV2 overrides={{width:'15px', height:'7px', marginLeft:'10px'}} image={Arrow}/>
                                                            </Row>
                                                        </Typography.Regular>
                                                    </Button>
                                                }
                                        </Col>
                                    </Col>
                                </Row>
                                { showControls &&
                                    <Row overrides={{height:'100%', paddingRight:'20px', paddingLeft:'20px', marginBottom:'20px'}}>
                                        <Col overrides={{alignItems:'flex-start', justifyContent:'flex-end'}}>
                                            <div style={{display:'flex', alignItems:'center', justifyContent: 'center', height:'35px', width:'140px', backgroundColor:'#D9D9D9'}} bold>
                                                <Typography.Regular fontSize={'12px'} overrides={{color:'#000000'}} bold>
                                                    Exhibit - {itemInView + 1} / {kidsImages.length}
                                                </Typography.Regular>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </GridWrapper>
                        </CentralizedBackgroundImage>
                    </div>
                    <GridWrapper overrides={{marginBottom:'100px', width:'1100px'}}>
                        <Row>
                            <Col>
                                <Header data={kidsImagesPageData}/>
                            </Col>
                        </Row>
                        <Row overrides={{alignItems:'flex-start', marginTop:'40px'}}>
                            <Col width={12} overrides={{justifyContent:'flex-start'}}>
                                <div style={{height:'100%', width:'100%'}} ref={ref}><Images openImage={openImage} images={kidsImages} /></div>
                            </Col>
                            <Col width={4}/>
                            <Col width={8}>
                                <Fader>
                                <div
                                    style={{
                                        position: stickExhibit === true ? 'fixed' : 'relative',
                                        width: stickExhibit === true ? '367px' : 'inherit',
                                        top: stickExhibit === true ? '20px' : undefined
                                    }}
                                >
                                    <Exhibit 
                                        image={kidsImagesPageData.exhibitImage} 
                                        text={kidsImagesPageData.exhibitText} 
                                        version='kids'
                                    />
                                </div>
                                </Fader>
                            </Col>
                        </Row>
                    </GridWrapper>
                </motion.div>}
            </AnimatePresence>
        </GridWrapper>
    )

}

export default KidsImageGallery