import React from "react";
import { Link } from "react-router-dom";
import { GridWrapper, Row, Col } from "../../globalComponents/Grid";
import {
    ImageV2,
    CentralizedBackgroundImage,
} from "../../globalComponents/Image";
import { Typography } from "../../globalComponents/Typography";
import Arrow from "../../assets/images/arrow.png";
import Viewall from "../../assets/images/viewall.png";
import Fader from "../../globalComponents/Fader";
import { parseISO, format } from "date-fns";
import { motion } from "framer-motion";

const getFeatured = (events) => {
    return events
        .filter((x) => x.featured === true)
        .sort((a, b) => {
            a.sortDate = new Date(a.date);
            b.sortDate = new Date(b.date);
            return b.sortDate - a.sortDate;
        });
};
const getNonFeatured = (events) => {
    return events
        .filter((x) => x.featured !== true)
        .sort((a, b) => {
            a.sortDate = new Date(a.date);
            b.sortDate = new Date(b.date);
            return b.sortDate - a.sortDate;
        });
};

export const EventsList = ({ events, linkTo }) => {
    if (events.length === 1) {
        let event = events[0];
        return (
            <GridWrapper overrides={{ marginTop: "30px" }}>
                <Row overrides={{ justifyContent: "flex-start" }}>
                    <div
                        style={{
                            display: "flex",
                            overflow: "scroll",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "390px",
                            padding: "5px",
                        }}
                    >
                        <Event key={event.image} event={event} />
                    </div>
                </Row>
                <Row overrides={{ marginTop: "20px" }}>
                    <Col overrides={{ alignItems: "flex-start" }}>
                        <Link
                            style={{ textDecoration: "none", color: "black" }}
                            to={linkTo}
                        >
                            <Typography.Regular
                                fontSize={"15px"}
                                overrides={{
                                    fontFamily: "StolzlRegular",
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "row",
                                }}
                            >
                                View All Events
                                <ImageV2
                                    overrides={{
                                        width: "25px",
                                        marginLeft: "10px",
                                    }}
                                    image={Arrow}
                                />
                                <ImageV2
                                    overrides={{
                                        width: "32px",
                                        marginLeft: "10px",
                                    }}
                                    image={Viewall}
                                />
                            </Typography.Regular>
                        </Link>
                    </Col>
                </Row>
            </GridWrapper>
        );
    }

    return (
        <GridWrapper overrides={{ marginTop: "30px" }}>
            <Row overrides={{ justifyContent: "flex-start" }}>
                <div
                    style={{
                        display: "flex",
                        overflow: "scroll",
                        scrollSnapType: "x mandatory",
                        width: "100%",
                        padding: "5px",
                    }}
                >
                    {getFeatured(events)
                        ?.slice(0, 3)
                        .map((event) => {
                            return <Event key={event.image} event={event} />;
                        })}
                </div>
            </Row>
            <Row overrides={{ marginTop: "20px" }}>
                <Col overrides={{ alignItems: "flex-start" }}>
                    <Link
                        style={{ textDecoration: "none", color: "black" }}
                        to={linkTo}
                    >
                        <Typography.Regular
                            fontSize={"15px"}
                            overrides={{
                                fontFamily: "StolzlRegular",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                            }}
                        >
                            View All Events
                            <ImageV2
                                overrides={{
                                    width: "25px",
                                    marginLeft: "10px",
                                }}
                                image={Arrow}
                            />
                            <ImageV2
                                overrides={{
                                    width: "32px",
                                    marginLeft: "10px",
                                }}
                                image={Viewall}
                            />
                        </Typography.Regular>
                    </Link>
                </Col>
            </Row>
        </GridWrapper>
    );
};

export const EventsGrid = ({ events }) => {
    let featuredRows = [];
    let nonFeaturedRows = [];
    let size = 1;

    let featured = getFeatured(events);
    let nonFeatured = getNonFeatured(events);

    //split into rows of size
    for (let i = 0; i < featured.length; i += size)
        featuredRows.push(featured.slice(i, i + size));
    for (let i = 0; i < nonFeatured.length; i += size)
        nonFeaturedRows.push(nonFeatured.slice(i, i + size));

    //if the last row has < size. Add null elements
    if (featuredRows.length > 0)
        while (featuredRows[featuredRows.length - 1].length < size)
            featuredRows[featuredRows.length - 1].push(null);

    if (nonFeaturedRows.length > 0)
        while (nonFeaturedRows[nonFeaturedRows.length - 1].length < size)
            nonFeaturedRows[nonFeaturedRows.length - 1].push(null);

    return (
        <GridWrapper>
            <Row>
                <Typography.Header fontSize="35px">Featured</Typography.Header>
            </Row>
            {featuredRows.map((row, rowIndex) => {
                return (
                    <Fader key={`eventRow${rowIndex}`}>
                        <Row
                            overrides={{
                                justifyContent: "center",
                                marginTop: "30px",
                            }}
                        >
                            {row.map((col, colIndex) => {
                                if (!col) return <Col></Col>;
                                return (
                                    <Col
                                        overrides={{
                                            marginLeft: "20px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Event
                                            key={`eventCol${rowIndex}${colIndex}`}
                                            event={col}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Fader>
                );
            })}
            <Row overrides={{ marginTop: "100px" }}>
                <Typography.Header fontSize="35px">
                    Previous Events
                </Typography.Header>
            </Row>
            {nonFeaturedRows.map((row, rowIndex) => {
                return (
                    <Fader key={`eventRow${rowIndex}`}>
                        <Row
                            overrides={{
                                justifyContent: "center",
                                marginTop: "30px",
                            }}
                        >
                            {row.map((col, colIndex) => {
                                if (!col) return <Col></Col>;
                                return (
                                    <Col
                                        overrides={{
                                            marginLeft: "20px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Event
                                            key={`eventCol${rowIndex}${colIndex}`}
                                            event={col}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Fader>
                );
            })}
        </GridWrapper>
    );
};

export const Event = ({ event }) => {
    const img = event.image;

    let link = event?.link;

    link = link?.replace("www.", "");
    if (!link?.includes("https://")) link = `https://${link}`;

    if (event === null) return <div></div>;

    const genColor = () => {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const isFree = (v) => {
        let v2 = Number(v)
        if(isNaN(v2)) return ''
        return '£'
    }

    return (
        <motion.div
            whileHover={{
                y: -3,
            }}
            whileTap={{
                scale: 0.95,
            }}
            onClick={() => {
                if (event.poster) {
                    window.open(event.poster, "_blank");
                } else if (link) {
                    window.open(link, "_blank");
                }
            }}
            style={{
                width: "270px",
                minWidth: "270px",
                border: "solid #383838 4px",
                marginRight: "20px",
                cursor: "pointer",
            }}
        >
            <GridWrapper>
                <Row overrides={{ justifyContent: "flex-end", height: "6px" }}>
                    <div
                        style={{
                            height: "100%",
                            width: "13px",
                            backgroundColor: genColor(),
                        }}
                    ></div>
                    <div
                        style={{
                            height: "100%",
                            width: "13px",
                            backgroundColor: genColor(),
                        }}
                    ></div>
                    <div
                        style={{
                            height: "100%",
                            width: "13px",
                            backgroundColor: genColor(),
                        }}
                    ></div>
                </Row>
                <Row
                    overrides={{
                        padding: "10px",
                        borderBottom: "solid #383838 2px",
                        height: "120px",
                    }}
                >
                    <Col
                        overrides={{ height: "100%", alignItems: "flex-start" }}
                    >
                        <div
                            style={{ width: "100%", justifySelf: "flex-start" }}
                        >
                            <Typography.Regular bold fontSize={"19px"}>
                                {event.title}
                            </Typography.Regular>
                        </div>
                        <div
                            style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "flex-end",
                            }}
                        >
                            <Typography.Regular
                                fontSize={"15px"}
                                overrides={{ fontFamily: "StolzlBook" }}
                            >
                                {format(parseISO(event.date), "dd/MM/yyyy")}
                            </Typography.Regular>
                        </div>
                    </Col>
                </Row>
                <Row
                    overrides={{
                        height: "120px",
                        borderBottom: "solid #383838 2px",
                    }}
                >
                    <Col
                        width={1}
                        overrides={{ borderRight: "solid #383838 2px" }}
                    >
                        <Typography.Regular
                            bold
                            fontSize={
                                event.price === 0 || !event.price
                                    ? "18px"
                                    : "25px"
                            }
                        >
                            {isFree(event?.price)}
                            {event.price === 0 || !event.price
                                ? "Free"
                                : event.price}
                        </Typography.Regular>
                    </Col>
                    <Col
                        width={2}
                        overrides={{
                            alignItems: "flex-start",
                            padding: "10px",
                        }}
                    >
                        <Typography.Regular
                            fontSize={"16px"}
                            overrides={{
                                fontFamily: "StolzlBook",
                                overflow: "hidden",
                            }}
                        >
                            {event.location}
                        </Typography.Regular>
                        <Typography.Regular
                            fontSize={"13px"}
                            overrides={{
                                fontFamily: "StolzlBold",
                                overflow: "hidden",
                                marginTop: "10px",
                            }}
                        >
                            {event.time}
                        </Typography.Regular>
                    </Col>
                </Row>
                <Row
                    overrides={{
                        overflow: "hidden",
                        padding: "20px",
                        height: "170px",
                    }}
                >
                    <Typography.Regular
                        fontSize={"16px"}
                        overrides={{
                            fontFamily: "StolzlBook",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: 6,
                            WebkitBoxOrient: "vertical",
                            whiteSpace: "normal",
                            lineHeight: "22px",
                            padding: "3px",
                        }}
                    >
                        {event.description}
                    </Typography.Regular>
                </Row>
                <Row
                    overrides={{
                        padding: "20px",
                        marginTop: "5px",
                        paddingTop: "0px",
                        height: "220px",
                    }}
                >
                    <Col>
                        <CentralizedBackgroundImage image={img} print={true} />
                    </Col>
                </Row>
            </GridWrapper>
        </motion.div>
    );
};
