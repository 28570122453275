import {useEffect, useContext, useState} from 'react'
import { dataStruct } from '../database/dataStruct'
import { ContextManager } from '../context/_index'
import {preloadImages} from '../utils/preloadImages'
import {getDocument, getImageUrl, getCollection, updateDocument, uploadImage, createDocument, deleteDocument, deleteImage} from '../utils/firebaseHelpers'
import { generateRandomString } from '../utils/randomStringGenerator'
import sanityClient from "@sanity/client";

let client = sanityClient({
  projectId: "97fmu3em",
  dataset: "production",
  useCdn: true
});

export const getData = async () => {
    return await client.fetch(`
        *[_type == "brumImages"]
        {
          key, 
          "image": image.asset->url,
          _createdAt
        }`)
    .then(x => {return x})
}

const useBrumImageGallery = () => {
    
    const [data, setData] = useState(null)
    const [imagesLoaded, setImagesLoaded] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [itemInView, setItemInView] = useState(0)

    useEffect(() => {get()},[])

    const get = async () => {
        let response = await getData()

        preloadImages([
            ...(response.map(x => {return x.image})),
        ], setImagesLoaded)
        
        response = response.sort((a,b) => {
            try{
                a.key = parseInt(a.key)
                b.key = parseInt(b.key)
                return a.key - b.key
            }catch(e){
                a.key = 9999
                b.key = 10000
                return a.key - b.key
            }
        })

        setData(response)
    }
    
    const onNext = () => {
        if(itemInView < data.length - 1){
            setItemInView(itemInView + 1)
        }
    }

    const onPrev = () => {
        if(itemInView > 0){
            setItemInView(itemInView - 1)
        }
    }

    // handle modal close
    useEffect(() => {
        if(modalOpen){document.addEventListener('click', (e) => {
            setModalOpen(false)
        })}
        else { document.removeEventListener('click', () => {console.log("remove event")})}
    },[modalOpen])

    const openImage = (id) => {
        if(modalOpen){
            setModalOpen(false)
        }else{
            setModalOpen(true)
            let index = data.map(x => {return x.image}).indexOf(id)
            setItemInView(index)
        }
    }

    const closeImage = () => {
        setModalOpen(false)
    }

    return {
        brumImages: data,
        brumImagesLoaded: imagesLoaded,
        onNext,
        onPrev,
        openImage,
        closeImage,
        modalOpen,
        itemInView,
        getBrumImages: get
    }
}

export default useBrumImageGallery