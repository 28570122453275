import React from 'react'
import {GridWrapper, Row, Col} from '../../../globalComponents/Grid'
import { MaskedImageColumnsSmall } from '../../../globalComponents/Image'
import Separator from '../../../globalComponents/Separator'
import { Typography, TypographyTitan } from '../../../globalComponents/Typography'
import {motion} from 'framer-motion'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Header = ({text, mainImage}) => {

    let nav = useNavigate()
    const [hover, setHover] = useState(false)

    return(
        <GridWrapper >
            <Row>
                <Col>
                    <MaskedImageColumnsSmall
                        image={mainImage}
                        height='400px'
                    />
                </Col>
            </Row>

            <Row overrides={{marginTop:'10px', justifyContent:'flex-start', gap:'10px'}}>
                <Typography.Regular fontSize={'19px'} overrides={{whiteSpace:'nowrap', fontFamily: 'StolzlBold'}}>A new way of socialising</Typography.Regular>
                <div style={{width:'100%', height:'3px', backgroundColor:'#222222'}}></div>
            </Row>

            <Row overrides={{marginTop:'15px'}}>
                <Col overrides={{alignItems:'flex-start'}}>
                    <Col overrides={{justifyContent:'flex-start', alignItems:'baseline', flexDirection:'row'}}>
                        <TypographyTitan.Header fontSize='65px' overrides={{lineHeight:'70%'}} color='#5BB9A7' bold>K</TypographyTitan.Header>
                        <TypographyTitan.Header fontSize='65px' overrides={{lineHeight:'70%'}} color='#EF8DB6' bold>i</TypographyTitan.Header>
                        <TypographyTitan.Header fontSize='65px' overrides={{lineHeight:'70%'}} color='#49A4CF' bold>d</TypographyTitan.Header>
                        <TypographyTitan.Header fontSize='65px' overrides={{lineHeight:'70%'}} color='#EAB853' bold>z</TypographyTitan.Header>
                    </Col>
                </Col>
            </Row>
            <Row overrides={{marginTop:'15px'}}>
                <Col overrides={{alignItems:'flex-start'}}>
                    <TypographyTitan.Header fontSize='65px' bold overrides={{lineHeight:'70%'}}>Events.</TypographyTitan.Header>
                </Col>
            </Row>

            <Row overrides={{gap:'10px', marginTop:'10px'}}>
                <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => nav('/')} style={{display:'flex', alignItems:'center', cursor: hover ? 'pointer' : 'default'}}>
                    <div>
                        <Typography.Regular fontSize={'19px'}>Back</Typography.Regular>
                            <motion.div style={{width:'100%', height:'2px', backgroundColor:'#222222'}} key='5j4kl325j4kl3'
                                animate={{
                                    opacity: hover ? 1 : 0
                                }}
                            />
                    </div>
                    <svg
                        style={{transform:"rotate(180deg)", marginRight:'-7px',}}
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        >
                        <path
                            d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"
                            fill="currentColor"
                        />
                    </svg>
                </div>
                <div style={{width:'100%', height:'4px', backgroundColor:'#222222'}}></div>
            </Row>
            
            <div style={{width:'100%', marginTop:'20px'}}>
                <Typography.Regular fontSize={'17px'} overrides={{fontFamily:'StolzlBook'}}>
                    <div style={{wordWrap:'break-word'}} dangerouslySetInnerHTML={{ __html: text }}></div>
                </Typography.Regular>
            </div>

            {/* <Row overrides={{marginTop:'30px'}}>
                <Col width={2} overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular fontSize={'17px'} overrides={{fontFamily:'StolzlBook'}}>
                        <div dangerouslySetInnerHTML={{ __html: text }}></div>
                    </Typography.Regular>
                </Col>
            </Row> */}

            {/* <Row overrides={{alignItems:'flex-start', marginTop:'10px'}}>
                <Col width={10} overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular fontSize={'17px'} overrides={{fontFamily:'StolzlBook', marginTop:'30px'}}>
                        <div dangerouslySetInnerHTML={{ __html: text }}></div>
                    </Typography.Regular>
                </Col>
            </Row> */}

        </GridWrapper>
    )
}

export default Header