import React, {useContext} from 'react'
import '../assets/fonts.css'
import '../cssFiles/webkitBox.css'
import { ContextManager } from '../context/_index'
import { ScreenTypes } from '../constants/windowSize'

const fontFamily = 'StolzlBold'
const defaultColor = '#383838'

const fontFamily2 = 'Titan'

export const Typography = {
    Header: ({children, fontSize = '100px', color = defaultColor, bold, overrides}) => {
        
        return(
            <span style={{
                fontSize: fontSize, 
                fontFamily: fontFamily,
                fontWeight: bold ? 'bold' : '',
                color: color,
                ...overrides
            }}>
                {children}
            </span>
        )        
    },
    Regular: ({children, fontSize, color = defaultColor, bold, overrides, oneLine, applywebkitbox}) => {
        const { screenType } = useContext(ContextManager.WindowSize)

        //map screen type to font size
        let size = null
        if(screenType === ScreenTypes.large_screen) size = '16px'
        else if(screenType === ScreenTypes.smallLarge_screen) size = '15px'
        else if(screenType === ScreenTypes.med2_screen) size = '13px'
        else if(screenType === ScreenTypes.med1_screen) size = '11px'
        else size='11px'

        return(
            <div className={applywebkitbox && 'webkitBox'} style={{
                fontSize: fontSize ? fontSize : size, 
                fontFamily: fontFamily,
                fontWeight: bold ? 'bold' : '',
                color: color,
                whiteSpace: oneLine ? 'nowrap' : 'pre-wrap',
                textOverflow: 'ellipsis',
                ...overrides
            }}>
                {children}
            </div>
        )
    }
}

export const TypographyTitan = {
    Header: ({children, fontSize = '100px', color = defaultColor, bold, overrides}) => {
        return(
            <div style={{
                fontSize: fontSize, 
                fontFamily: fontFamily2,
                fontWeight: bold ? 'bold' : '',
                color: color,
                ...overrides
            }}>
                {children}
            </div>
        )        
    },
    Regular: ({children, fontSize, color = defaultColor, bold, overrides}) => {
        const { screenType } = useContext(ContextManager.WindowSize)

        //map screen type to font size
        let size = null
        if(screenType === ScreenTypes.large_screen) size = '17px'
        if(screenType === ScreenTypes.smallLarge_screen) size = '16px'
        if(screenType === ScreenTypes.med2_screen) size = '14px'
        if(screenType === ScreenTypes.med1_screen) size = '12px'
        else size='12px'

        return(
            <div style={{
                fontSize: fontSize ? fontSize : size, 
                fontFamily: fontFamily2,
                fontWeight: bold ? 'bold' : '',
                color: color,
                ...overrides
            }}>
                {children}
            </div>
        )
    }
}