import React from 'react'
import {GridWrapper, Row, Col} from '../../../globalComponents/Grid'
import { CentralizedBackgroundImage} from '../../../globalComponents/Image'

const ImageDisplay = ({images}) => {

    let main = ''
    let topRow = []
    let bottomRow = []

    if(images.length > 1) {
        main = images[0]
        topRow = [images[1], images[2]]
    }

    if(images.length > 4) {
        main = images[0]
        topRow = [images[1], images[2]]
        bottomRow = [images[3], images[4]]
    }

    else main = images[0]

    return (
        <GridWrapper>
            
            <Row overrides={{height:'390px'}}>
                <Col>
                    {main && <CentralizedBackgroundImage image={main.image}/>}
                </Col>
            </Row>
            {topRow.length > 0 && <Row overrides={{height:'170px', marginTop:'8px', justifyContent:'space-between', width:'100%'}}>
                {topRow.map((image, index) => {
                    if(image) return <CentralizedBackgroundImage overrides={{width:'49%'}} image={image.image}/>
                })}
            </Row>}
            {bottomRow.length > 0 && <Row overrides={{height:'170px', marginTop:'8px', justifyContent:'space-between', width:'100%'}}>
                {bottomRow.map((image, index) => {
                    if(image) return <CentralizedBackgroundImage overrides={{width:'49%'}} image={image.image}/>
                })}
            </Row>}

        </GridWrapper>
    )
}

export default ImageDisplay