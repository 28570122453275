import {useEffect, useContext, useState} from 'react'
import { dataStruct } from '../database/dataStruct'
import { ContextManager } from '../context/_index'
import {preloadImages} from '../utils/preloadImages'
import {getDocument, getImageUrl, getCollection, updateDocument, uploadImage, createDocument, deleteDocument, deleteImage} from '../utils/firebaseHelpers'
import { generateRandomString } from '../utils/randomStringGenerator'
import sanityClient from "@sanity/client";
import blocksToHtml from '@sanity/block-content-to-html'

// copied from sanity docs https://www.sanity.io/plugins/block-content-to-html
const h = blocksToHtml.h
const serializers = {
    types: {
      code: props => (
        h('pre', {className: props.node.language},
          h('code', props.node.code)
        )
      )
    }
}

let client = sanityClient({
  projectId: "97fmu3em",
  dataset: "production",
  useCdn: true
});

export const getData = async () => {
    let res = await client.fetch(`
        *[_type == "storyItems"]
        {
          text, 
          "image": image.asset->url
        }`)
    .then(x => {return x})
    return res
}

const useStoryItems = () => {
    
    const [data, setData] = useState(null)
    const [imagesLoaded, setImagesLoaded] = useState(false)

    useEffect(() => {get()},[])

    const get = async () => {
        let response = await getData()
        
        preloadImages([
            ...(response.map(x => {return x.image})),
        ], setImagesLoaded)
        
        response = response.map(x => {
            return {
                image: x.image,
                text: blocksToHtml({
                    blocks: x?.text,
                    serializers: serializers
                })
            }
        })

        //let ordered = response.sort((a,b) => a.createdAt - b.createdAt);
        setData(response)
    }

    return {
        storyItems: data,
        storyImagesLoaded: imagesLoaded,
        getStoryItems: get
    }
}

export default useStoryItems