import React from 'react'
import { GridWrapper, Row, Col } from '../../../../globalComponents/Grid'
import { Typography } from '../../../../globalComponents/Typography'
import { ImageV2, MaskedImageColumns, MaskedImageColumnsSmall } from '../../../../globalComponents/Image'
import Partners from './Partners'
import Fader from '../../../../globalComponents/Fader'
import Separator from '../../../../globalComponents/Separator'
import WhatPeopleThink from './WhatPeopleThink'

const Community = ({get, pageData, partnersData, addPartnerMode, setAddPartnerMode, testimonials}) => {

    return(
        <GridWrapper overrides={{marginTop:'50px'}}>
            <Fader>
            <Row>
                <Col overrides={{height:'3px', backgroundColor:'#222222'}}/>
            </Row>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'45px'}}>
                <Col>
                    <MaskedImageColumns height={'260px'} image={pageData.communityImageMain}/>
                </Col>
            </Row>
            </Fader>

            <Fader>
                <Row overrides={{marginTop:'30px'}}>
                    <Separator text={'Partnerships & Affiliates'}/>
                </Row>
            </Fader>

            <Fader>
            <Row>
                <Col overrides={{alignItems:'flex-start'}}>
                    <Typography.Header bold>Community</Typography.Header>
                </Col>
            </Row>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'40px'}}>
                <Col overrides={{height:'3px', backgroundColor:'#222222'}}/>
            </Row>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'40px', alignItems:'flex-start'}}>
                <Col width={10}>
                    <ImageV2 image={pageData.communityImage2}/>
                    <ImageV2 image={pageData.communityImage3} overrides={{marginTop:'60px'}}/>
                    <ImageV2 image={pageData.communityImage4} overrides={{marginTop:'60px'}}/>
                </Col>
                <Col width={1}/>
                <Col width={10} overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular overrides={{fontFamily:'StolzlBook', width:'550px'}}>
                        <div dangerouslySetInnerHTML={{ __html: pageData.communityText }}></div>
                    </Typography.Regular>
                </Col>
            </Row>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'50px'}}>
                <Col>
                    <Partners partners={partnersData} get={get}/>   
                </Col>
            </Row>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'50px'}}>
                <Col>
                    <WhatPeopleThink testimonials={testimonials} data={pageData}/>
                </Col>
            </Row>
            </Fader>

        </GridWrapper>
    )
}

export default Community