import React, { useState, useEffect, useContext } from 'react'
import {GridWrapper, Row, Col} from '../../../globalComponents/Grid'
import Header from './Header'
import {EventsGrid} from '../EventsComponents'
import useBrumEvents from '../../../dataHooks/useBrumEvents'
import useBrumEventsPage from '../../../dataHooks/useBrumEventsPage'
import { AnimatePresence, motion } from 'framer-motion'
import Loading from '../Loading'
import { ContextManager } from '../../../context/_index'
import { ScreenTypes } from '../../../constants/windowSize'

const BrumEvents = ({}) => {

    const [ready, setReady] = useState(false)

    const {
        getBrumEvents,
        brumEventImagesLoaded,
        brumEventsData
    } = useBrumEvents()
    
    const {
        getBrumEventsPage,
        brumEventsPageData,
        brumEventsPageImagesLoaded
    } = useBrumEventsPage()

    const { screenType } = useContext(ContextManager.WindowSize)

    return (
        <GridWrapper>
            <AnimatePresence>
                {!(ready && brumEventsData && brumEventsPageData && brumEventsPageImagesLoaded && brumEventImagesLoaded) && <Loading setReady={setReady}/>}
                {(ready && brumEventsData && brumEventsPageData && brumEventsPageImagesLoaded && brumEventImagesLoaded) && 
                    <motion.div
                        key={"unique_mainPageanimation_key"}
                        exit={{ opacity:0 }}
                        initial={{ opacity:0 }}
                        animate={{ opacity:1 }}
                        transition={{duration:1, delay: 1}}
                    >
                    <GridWrapper overrides={{width:screenType === ScreenTypes.mobile ? '360px' : '450px', marginBottom:'100px'}}>

                        <Row>
                            <Col>
                                <Header text={brumEventsPageData.text} mainImage={brumEventsPageData.mainImage}/>
                            </Col>
                        </Row>

                        <Row overrides={{marginTop:'10px'}}>
                            <Col width={50}>
                                <EventsGrid
                                    events={brumEventsData} 
                                />                      
                            </Col>
                        </Row>
                    </GridWrapper>
                </motion.div>}
            </AnimatePresence>
        </GridWrapper>
    )
}

export default BrumEvents