import React, {useContext} from 'react'
import {GridWrapper, Row, Col} from '../../../../globalComponents/Grid'
import { Typography } from '../../../../globalComponents/Typography'
import {MaskedImageColumns} from '../../../../globalComponents/Image'
import Nav from './Nav'
import Separator from '../../../../globalComponents/Separator'
import Fader from '../../../../globalComponents/Fader'
import Lottie from 'lottie-react'
import LottieAnim from '../../../../assets/lottie/mainHeader.json'
import { WindowSizeStore } from '../../../../context/WindowSizeStore'
import { ScreenTypes } from '../../../../constants/windowSize'

const Header = ({data}) => {

    const {windowSize, screenType} = useContext(WindowSizeStore)
    
    return(
        <GridWrapper>

            <Row>
                <Col>
                    <MaskedImageColumns
                        height={(windowSize.height < 700 && screenType === ScreenTypes.large_screen) ? '370px' : '550px'}
                        image={data.headerImage} 
                    />
                </Col>
            </Row>
            
            <Separator text={'A new way of socialising - V1'}/>

            <Row overrides={{justifyContent:'space-between'}}>
                <Col width={11} overrides={{alignItems:'flex-start'}}>
                    <Typography.Header bold fontSize='97px' overrides={{lineHeight:'135px'}}>Artsy Party</Typography.Header>
                </Col>
                <Col width={1}/>
                <Col width={6}>
                    <Nav/>
                </Col>
            </Row>

            <Row overrides={{alignItems:'flex-start', height:'220px', marginTop:'40px', borderTop:'solid #222222 4px', borderBottom:'solid #222222 4px'}}>
                <Col width={1} overrides={{padding:'20px', borderRight:'solid #222222 4px'}}>
                    <Fader>
                        <Lottie style={{width:'150px'}} animationData={LottieAnim} loop={true}/>
                    </Fader>
                </Col>
                <Col width={3} overrides={{justifyContent:'flex-start', alignItems:'flex-start'}}>
                    <Typography.Regular overrides={{color:'rgba(34,34,34,100)', fontFamily:'StolzlBook', padding:'40px', paddingTop:'30px'}}>
                        <div dangerouslySetInnerHTML={{ __html: data.headerText }}></div>
                    </Typography.Regular>
                </Col>
            </Row>

        </GridWrapper>
    )
}

export default Header