import React, {useContext, useEffect, useState } from 'react'
import { ContextManager } from '../context/_index'
import { ScreenTypes } from '../constants/windowSize'
import * as Screens from '../layouts/_index'
import { GridWrapper, Row, Col } from './Grid'

const ScreenSelector = () => {

    const { screenType } = useContext(ContextManager.WindowSize)

    useEffect(() => {
        console.log("Current Screen type: " + screenType)
    },[screenType])

    return (
        <GridWrapper>
            { (screenType === ScreenTypes.mobile || screenType === ScreenTypes.small_screen) && <Screens.Mobile/> }
            {/* { screenType === ScreenTypes.small_screen && <Screens.SmallScreen/> }
            { screenType === ScreenTypes.med1_screen && <Screens.Med1Screen/> }
            { screenType === ScreenTypes.med2_screen && <Screens.Med2Screen/> }
            { screenType === ScreenTypes.smallLarge_screen && <Screens.LargeSmallScreen/> } */}
            { screenType === ScreenTypes.large_screen && <Screens.LargeScreen/> }
        </GridWrapper>
    )
}

export default ScreenSelector