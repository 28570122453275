import React, { useState } from 'react'
import { GridWrapper, Row, Col } from '../../../../globalComponents/Grid'
import { Typography } from '../../../../globalComponents/Typography'
import { ImageV2, MaskedImageColumnsSmall } from '../../../../globalComponents/Image'
import Partners from './Partners'
import Fader from '../../../../globalComponents/Fader'
import Separator from '../../../../globalComponents/Separator'
import './css.css'
import WhatPeopleThink from './WhatPeopleThink'

const Community = ({get, pageData, partnersData, testimonials}) => {

    const [readMore, setReadMore] = useState(false)
    const [readHover, setReadHover] = useState(false)

    return(
        <GridWrapper overrides={{marginTop:'30px'}}>
            <Fader>
            <Row>
                <Col overrides={{height:'3px', backgroundColor:'#222222'}}/>
            </Row>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'75px', marginBottom:'65px'}}>
                <Col>
                    <MaskedImageColumnsSmall image={pageData.communityImageMain} height='260px'/>
                </Col>
            </Row>
            </Fader>

            <Fader>
                <Separator fontSize='12px' lineHeight='2px' text={'Partnerships & Affiliates'}/>
            </Fader>

            <Fader>
            <Row>
                <Col overrides={{marginTop:'10px', alignItems:'flex-start'}}>
                    <Typography.Header fontSize='50px' bold>Community</Typography.Header>
                </Col>
            </Row>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'35px'}}>
                <Col overrides={{height:'3px', backgroundColor:'#222222'}}/>
            </Row>
            </Fader>

            <Fader>
                <Row overrides={{marginTop:'40px'}}>
                    <Col width={10}>
                        <ImageV2 image={pageData.communityImage2}/>
                    </Col>
                </Row>
            </Fader>
   
            { readMore === false &&
                <Fader>
                    <div className='fadetextcontainer' style={{height:'350px', width:'100%'}}>
                        <div className='fadetextbody'>
                            <Row overrides={{marginTop:'20px', alignItems:'flex-start'}}>
                                <Col width={10} overrides={{alignItems:'flex-start'}}>
                                    <Typography.Regular fontSize={'17px'} overrides={{fontFamily:'StolzlBook', width:'100%'}}>
                                        <div dangerouslySetInnerHTML={{ __html: pageData.communityText }}></div>
                                    </Typography.Regular>
                                </Col>
                            </Row>                            
                        </div>
                    </div>
                    <div onClick={() => setReadMore(true)} style={{display:'flex', justifyContent:'flex-start', width:'100%'}}>
                        <Typography.Regular overrides={{whiteSpace:'nowrap', marginTop:'20px'}} fontSize={'15px'}>Read More</Typography.Regular>
                    </div>
                </Fader>
            }
            { readMore === true &&
                <Fader>
                    <Row overrides={{marginTop:'20px', alignItems:'flex-start'}}>
                        <Col width={10} overrides={{alignItems:'flex-start'}}>
                            <Typography.Regular fontSize={'17px'} overrides={{fontFamily:'StolzlBook', width:'100%'}}>
                                <div dangerouslySetInnerHTML={{ __html: pageData.communityText }}></div>
                            </Typography.Regular>
                        </Col>
                    </Row>
                    <div onClick={() => setReadMore(false)} style={{display:'flex', justifyContent:'flex-start', width:'100%'}}>
                        <Typography.Regular overrides={{whiteSpace:'nowrap', marginTop:'20px'}} fontSize={'15px'}>Read Less</Typography.Regular>
                    </div>
                </Fader>
            }

            <Fader>
            <Row overrides={{marginTop:'50px'}}>
                <Col>
                    <Partners partners={partnersData} get={get}/>   
                </Col>
            </Row>
            </Fader>

            
            <Fader>
            <Row overrides={{marginTop:'50px'}}>
                <Col>
                    <WhatPeopleThink testimonials={testimonials} data={pageData}/>   
                </Col>
            </Row>
            </Fader>

        </GridWrapper>
    )
}

export default Community