import React from 'react'
import { GridWrapper, Row, Col } from '../../../../globalComponents/Grid'
import { ImageV2 } from '../../../../globalComponents/Image'

const Partners = ({partners = [], get}) => {

    let rows = [], size = 4

    //split partners into rows of size
    for (let i = 0; i < partners.length; i += size) rows.push(partners.slice(i, i + size))

    if(rows.length > 0)
        //if the last row has < size. Add null elements
        while(rows[rows.length - 1].length < size) rows[rows.length - 1].push('')
    
    return (
        <GridWrapper>
            {rows.map((row, rowIndex) => {
                return (
                    <Row key={`community${rowIndex}`} overrides={{justifyContent:'flex-start', marginTop:'10px'}} >
                        {row.map((col, colIndex) => {
                            return <PartnerComponent partner={col} get={get}/>
                        })}
                    </Row>
                )
            })}
        </GridWrapper>
    )
}

const PartnerComponent = ({partner}) => {

    if(!partner) return (
        <Col>
            <div style={{width:'240px', height:'150px', display:'flex', justifyContent:'center', alignItems:'center'}}></div>
        </Col>
    )
    
    return (
        <Col overrides={{alignItems:'flex-start'}}>
            <div style={{width:'240px', height:'150px', display:'flex', justifyContent:'center', alignItems:'center',border:'solid #e8e8e8 3px'}}>
                <ImageV2 image={partner.image} overrides={{width:'60%'}}/>
            </div>
        </Col>
    )
}

export default Partners