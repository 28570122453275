import React from 'react'
import {motion} from 'framer-motion'

const Fader = ({children, time = 0.6, delay = 0.2}) => {

    return (
        <motion.div 
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent:'center',
                alignItems:'center',
                flex:'1',
                width:'100%'
            }}
            initial={{ opacity:0 }}
            whileInView={{opacity:1}}
            viewport={{ once: true }}
            transition={{duration: time, delay: delay, type: 'tween', ease:'easeIn' }}
        >
            {children}
        </motion.div>
    )
}

export default Fader