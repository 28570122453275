import React,{useContext} from 'react'
import Button from '../../../../globalComponents/Button'
import { GridWrapper, Row, Col } from '../../../../globalComponents/Grid'
import { ImageV2 } from '../../../../globalComponents/Image'
import { ContextManager } from '../../../../context/_index'

const Partners = ({partners = [], get}) => {

    let rows = [], size = 1

    //split partners into rows of size
    for (let i = 0; i < partners.length; i += size) rows.push(partners.slice(i, i + size))

    if(rows.length > 0)
        //if the last row has < size. Add null elements
        while(rows[rows.length - 1].length < size) rows[rows.length - 1].push('')
    
    return (
        <GridWrapper>
            <Row>
                <div style={{display:'flex', overflow:'scroll'}}>
                    {rows.map((row, rowIndex) => {
                        return (
                            <Col key={`community${rowIndex}`} overrides={{justifyContent:'flex-start', marginTop:'10px'}} >
                                {row.map((col, colIndex) => {
                                    return <PartnerComponent key={`community${rowIndex}_${colIndex}`} partner={col} get={get}/>
                                })}        
                            </Col>
                        )
                    })}
                </div>
            </Row>
        </GridWrapper>
    )
}

// const PartnerComponent = ({partner, get}) => {

    

//     if(!partner) return (
//         <Col>
//             <div style={{width:'100%', height:'130px', display:'flex', justifyContent:'center', alignItems:'center', marginRight:'10px'}}></div>
//         </Col>
//     )
    
//     return (
//         <Col >
//             <div style={{width:'200px', height:'160px', display:'flex', justifyContent:'center', alignItems:'center',border:'solid #e8e8e8 4px', marginRight:'10px'}}>
//                 <ImageV2 image={partner.image} overrides={{width:'50%'}}/>
//             </div>
//         </Col>
//     )
// }

const PartnerComponent = ({partner}) => {

    if(!partner) return (
        <Col>
            <div style={{marginLeft:'10px', width:'220px', height:'150px', display:'flex', justifyContent:'center', alignItems:'center', margin:'10px'}}></div>
        </Col>
    )
    
    return (
        <Col overrides={{alignItems:'flex-start'}}>
            <div style={{marginLeft:'10px', width:'220px', height:'150px', display:'flex', justifyContent:'center', alignItems:'center',border:'solid #e8e8e8 12px'}}>
                <ImageV2 image={partner.image} overrides={{width:'60%'}}/>
            </div>
        </Col>
    )
}

export default Partners