import React, {useState, useRef, useEffect, useContext} from 'react'
import { ContextManager } from '../../../../context/_index'
import { Link } from 'react-router-dom'
import {GridWrapper, Row, Col} from '../../../../globalComponents/Grid'
import { Typography, TypographyTitan } from '../../../../globalComponents/Typography'
import {EventsList} from '../../EventsComponents'
import Button from '../../../../globalComponents/Button'
import ForTheYoungstersImage from '../../../../assets/images/animatedBackgroundImage.jpg'
import { motion, useViewportScroll } from 'framer-motion'
import ImageDisplay from '../ImageDisplay'
import Separator from '../../../../globalComponents/Separator'
import Fader from '../../../../globalComponents/Fader'
import ButtonBackground from '../../../../assets/images/kidsButtonBackground.jpg'
import Lottie from 'lottie-react'
import LottieAnim from '../../../../assets/lottie/upcomingkids.json'
import Logo from '../../../../assets/images/logo.png'

import circleSVG from '../../../../assets/images/circle.svg'

const movingTextConfig = {
    startAtScroll: 0.245,
    stopAtScroll: 0.42,
    range: 50
}

const Kids = ({setRef, pageData, imagesData, eventsData, get}) => {

    const {refPositions} = useContext(ContextManager.RefPosition)
    const scrollToContact = () => {
        refPositions?.contact?.current?.scrollIntoView({behavior:'smooth'})
    }

    const [getInTouchHover, setGetInTouchHover] = useState(false)

    const ref = useRef()
    useEffect(() => {setRef(ref)},[])

    let animWrapStyle = {
        width: `350px`,
        height: `300px`,
        backgroundImage: `url(${pageData.kidsImage})`,
        backgroundSize:`cover`,
        maskImage: `url(${circleSVG})`,
        webkitMaskImage: `url(${circleSVG})`,
        maskSize: `300px, 200px`,
        webkitMaskSize: `300px, 200px`,
        maskRepeat: `no-repeat`,
        webkitMaskRepeat: `no-repeat`,
        maskPosition: `center`,
        webkitMaskPosition: `center`,
        maskComposite: `exclude`,
        webkitMaskComposite: `add`
    }

    return(
        <GridWrapper overrides={{marginTop:'120px',maxWidth:'100%', overflow:'hidden', borderTop:'solid #222222 3px'}}>
            <Fader>
                <TypographyTitan.Header fontSize='50px' overrides={{
                    backgroundImage:`url(${ForTheYoungstersImage})`,
                    backgroundSize:'cover',
                    WebkitBackgroundClip: "text",
                    backgroundClip: "text",
                    color:'transparent',
                    marginTop:'100px',
                    textAlign:'center'
                }}>
                    Something For The Youngsters.
                </TypographyTitan.Header>
                <Typography.Header overrides={{marginTop:'50px', textAlign:'center', fontFamily:'StolzlBold'}} fontSize='40px' bold>Art Can Make The Mind Grow.</Typography.Header>
            </Fader>

            <Row overrides={{ borderTop:'solid #222222 3px', marginTop:'100px' }}/>                

            <Fader>
            <Row overrides={{height:'300px',  marginTop:'100px'}}>
                <Col>
                    <motion.div key='h5342iuqopht4'
                        style={animWrapStyle}
                        animate={{
                            maskPosition:      ['50% 50%',    '80% 60%',   '70% 20%',      '10% 10%',      '10% 80%',      '30% 60%',      '90% 10%',      '50% 50%',    '50% 50%',       '50% 50%',       '50% 50%',       '50% 50%'],
                            WebkitMaskPosition:['50% 50%',    '80% 60%',   '70% 20%',      '10% 10%',      '10% 80%',      '30% 60%',      '90% 10%',      '50% 50%',    '50% 50%',       '50% 50%',       '50% 50%',       '50% 50%'],
                            maskSize:          ['25px, 25px', `45px, 45px`,'200px, 200px', '60px, 60px', '250px, 250px', '50px, 50px', '150px, 150px', '25px, 25px', '600px, 450px', '600px, 450px', '600px, 450px', '25px, 25px'],
                            WebkitMaskSize:    ['25px, 25px', `45px, 45px`,'200px, 200px', '60px, 60px', '250px, 250px', '50px, 50px', '150px, 150px', '25px, 25px', '600px, 450px', '600px, 450px', '600px, 450px', '25px, 25px'],
                        }}
                        transition={{ 
                            repeat: Infinity, 
                            duration: 20
                        }}
                    >
                    </motion.div>
                </Col>
            </Row>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'100px', justifyContent:'flex-start', gap:'10px'}}>
                <Typography.Regular overrides={{whiteSpace:'nowrap', fontFamily: 'StolzlBold'}}>Artsy Party </Typography.Regular>
                <TypographyTitan.Regular overrides={{whiteSpace:'nowrap', marginLeft:'-5px'}} fontSize={'13px'}>Kidz C.I.C</TypographyTitan.Regular>
                <div ref={ref} style={{width:'100%', height:'3px', backgroundColor:'#222222'}}></div>
            </Row>
            </Fader>
            
            <Fader>
            <Row overrides={{marginTop:'20px'}}>
                <Col overrides={{justifyContent:'flex-start', alignItems:'flex-start', flexDirection:'row'}}>
                    <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#5BB9A7' fontSize='118px' bold>K</TypographyTitan.Header>
                    <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#EF8DB6' fontSize='118px' bold>I</TypographyTitan.Header>
                    <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#49A4CF' fontSize='118px' bold>D</TypographyTitan.Header>
                    <TypographyTitan.Header overrides={{lineHeight:'70%'}} color='#EAB853' fontSize='118px' bold>Z</TypographyTitan.Header>
                </Col>
            </Row>
            </Fader>

            <Fader>
                <Row overrides={{marginTop:'30px'}}>
                    <Col overrides={{alignItems:'flex-start'}}>
                        <Typography.Regular overrides={{fontFamily:'StolzlBook'}} fontSize='17px'>
                            <div dangerouslySetInnerHTML={{ __html: pageData.kidsText }}></div>
                        </Typography.Regular>
                        <div
                            onMouseEnter={() => setGetInTouchHover(true)} 
                            onMouseLeave={() => setGetInTouchHover(false)} 
                            onClick={() => scrollToContact()} 
                            style={{
                                marginTop:'50px',
                                display:'flex', 
                                alignItems:'center', 
                                justifyContent:'center', 
                                background: `url(${ButtonBackground})`, 
                                backgroundSize:'cover',
                                backgroundPosition:'center',
                                height:'38px', 
                                width:'200px', 
                                borderRadius:'5px', 
                                color:'white',
                                cursor: getInTouchHover ? 'pointer' : 'default'
                            }}>
                            <Typography.Regular fontSize={'16px'} overrides={{fontFamily:'StolzlRegular', color:'white'}}>Get in touch</Typography.Regular>
                        </div>
                    </Col>
                </Row>
            </Fader>

            <Fader>
            <Row overrides={{alignItems:'flex-start', marginTop:'50px'}}>
                <Col width={10}>
                    <Link to='/imagegallery/kids' style={{textDecoration:'none', width:'100%'}}>
                        <ImageDisplay images={imagesData}/>
                        <Separator lineHeight='2px' fontFamily={'StolzlRegular'} text={'View Gallery'} isLink linkTo='/imagegallery/kids'/>
                    </Link>
                </Col>
            </Row>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'100px'}}>
                    <Col overrides={{alignItems:'flex-start'}}>
                        <TypographyTitan.Header overrides={{marginTop:'10px', letterSpacing:'2px'}} fontSize='50px' bold>Upcoming</TypographyTitan.Header>
                        <Row overrides={{marginTop:'20px'}}>
                            <Col width={1} overrides={{alignItems:'flex-start'}}>
                                <TypographyTitan.Header fontSize='50px' overrides={{letterSpacing:'2px'}} bold>Events</TypographyTitan.Header>
                            </Col>
                            <Col width={2} overrides={{alignItems:'flex-start', marginLeft:'20px'}}>
                                <Lottie style={{width:'50px'}} animationData={LottieAnim}/>
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
            </Fader>

            <Fader>
                <EventsList events={eventsData} linkTo={'/events/kids'}/>
            </Fader>

        </GridWrapper>
    )
}

export default Kids