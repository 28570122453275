import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import { GridWrapper, Row, Col } from '../../globalComponents/Grid'
import BrumEvents from './BrumEvents/Page'
import BrumImageGallery from './BrumGallery/Page'
import KidsImageGallery from './KidsGallery/Page'
import Home from './Home/Home'
import KidsEvents from './KidsEvents/Page'

const Main = () => {

    return(
        <GridWrapper>
            <Row overrides={{alignItems:'flex-start'}}>
                <Col width={2}/>
                <Col width={13}>
                    <Router>
                        <Routes>
                            <Route path='/' element={<Home />}/>
                            <Route path='/scrollToContact' element={<Home  scrollToContactOnLoad={true}/>}/>
                            <Route path='/events/brum' element={<BrumEvents />}/>
                            <Route path='/events/kids' element={<KidsEvents />}/>
                            <Route path='/imagegallery/brum' element={<BrumImageGallery />}/>
                            <Route path='/imagegallery/kids' element={<KidsImageGallery />}/>
                        </Routes>
                    </Router>
                </Col>
                <Col width={2}/>
            </Row>
        </GridWrapper>
    )
}


export default Main