import { GridWrapper, Row, Col } from "../../globalComponents/Grid"
import { CentralizedBackgroundImage } from "../../globalComponents/Image"
import { Typography } from "../../globalComponents/Typography"
import Button from "../../globalComponents/Button"
import { Link } from "react-router-dom"
import PaintBrush from '../../assets/images/paintBrush.png'
import Lottie from "lottie-react"
import anim from '../../assets/lottie/kidsexhibit.json'
import anim2 from '../../assets/lottie/brumexhibit.json'

const Exhibit = ({image, text, version, imageOverrides = {}}) => {
    
    return (
        <GridWrapper>
            <Row overrides={{height:'220px'}}>
                <Col>
                    <CentralizedBackgroundImage image={image} overrides={{...imageOverrides}}/>
                </Col>
            </Row>
            <Row overrides={{marginTop:'20px'}}>
                <Col overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular bold fontSize={'25px'}>Want To Get Involed?</Typography.Regular>
                </Col>
            </Row>
            <Row>
                <Col overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular overrides={{fontFamily:'StolzlRegular'}} fontSize={'15px'}>Exhibit 1.01</Typography.Regular>
                </Col>
            </Row>
            <Row overrides={{borderTop:'solid #383838 2px', borderBottom:'solid #383838 2px', height:'150px', marginTop:'20px'}}>
                <Col width={1} overrides={{borderRight:'solid #383838 2px', height:'100%'}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', width:'100%', height:'100%'}}>
                        <Lottie animationData={version === 'kids' ? anim : anim2}/>
                    </div>
                </Col>
                <Col width={3} overrides={{marginLeft:'10px'}}>
                    <Typography.Regular overrides={{fontFamily:'StolzlBook'}} fontSize={'14px'}>{text}</Typography.Regular>
                </Col>
            </Row>
            <Row>
                <Col overrides={{alignItems:'flex-start', marginTop:'20px'}}>
                    <Link style={{textDecoration:'none'}} to="/scrollToContact" >
                        <Button >
                            Get In Touch
                        </Button>
                    </Link>
                    
                </Col>
            </Row>
        </GridWrapper>
    )
}

export default Exhibit