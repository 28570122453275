import React,{useRef, useEffect, useState} from 'react'
import { Col, GridWrapper, Row } from '../../../../globalComponents/Grid'
import { CentralizedBackgroundImage, ImageV2 } from '../../../../globalComponents/Image'
import mainImage from '../../../../assets/images/theStory.png'
import { Typography } from '../../../../globalComponents/Typography'
import StoryItemComponent from './StoryItemComponent'
import Fader from '../../../../globalComponents/Fader'
import Arrow from '../../../../assets/images/arrow.png'
import {motion} from 'framer-motion'

const TheStory = ({setRef, data, create, setCreate, get}) => {

    const [hover, setHover] = useState(false)

    const ref = useRef()
    useEffect(() => {setRef(ref)},[])

    const [readMore, setReadMore] = useState(false)

    return(
        <GridWrapper>

            <Fader>
            <Row overrides={{marginTop:'200px', marginBottom:'-25px'}}>
                <Col width={3} overrides={{backgroundColor:'#222222', height:'4px'}}/>
            </Row>
            </Fader>

            <Fader>
            <div style={{width:'100%', marginTop:'40px'}} ref={ref}>
                <Row>
                    <Col>
                        <Typography.Header bold>The Story</Typography.Header>
                    </Col>
                </Row>
            </div>
            </Fader>

            <Fader>
            <Row overrides={{marginTop:'15px', marginBottom:'-25px'}}>
                <Col width={3} overrides={{backgroundColor:'#222222', height:'4px'}}/>
            </Row>
            </Fader>

            <Row overrides={{marginTop:'50px'}}>
                <Col width={1}/>
                <Col width={3}>
                    <ImageV2 image={data[0].image}/>
                </Col>
                <Col width={1}/>
            </Row>
            <Row overrides={{marginTop:'25px'}}>
                <Col width={1}/>
                <Col width={3} overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular overrides={{fontFamily:'StolzlBook', textAlign:'left'}}>
                        <div dangerouslySetInnerHTML={{ __html: data[0].text }}></div>
                    </Typography.Regular>
                </Col>
                <Col width={1}/>
            </Row>
            
            {readMore && data.slice(1).map((item, index) => {
                return (
                    <Fader>
                        <StoryItemComponent key={`storyItem_${index}`} get={get} item={item} />
                    </Fader>
                )
            })}

            {!readMore &&
                <Row overrides={{marginTop:'20px'}}>
                    <Col width={1}/>
                    <Col width={3} overrides={{flexDirection:'row', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <Row>
                            <Col>
                                <Row>
                                    <div style={{flex:3, cursor:'pointer'}} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => setReadMore(true)}>
                                        <Row>
                                            <Col width={4}>
                                                <Typography.Regular overrides={{display:'flex', alignItems:'center', fontFamily:'StolzlRegular'}}>
                                                    Read More 
                                                </Typography.Regular>
                                                <motion.div style={{width:'87%', height:'2px', backgroundColor:'#222222'}} key='5j4kl325j4kl3'
                                                    animate={{
                                                        opacity: hover ? 1 : 0
                                                    }}
                                                />
                                            </Col>
                                            <Col width={2} overrides={{alignItems:'flex-start'}}>
                                                <img src={Arrow}/>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Col width={10}/>
                                </Row>
                            </Col>
                        </Row>
                    </Col>    
                    <Col width={1}/>
                </Row>
            }

        </GridWrapper>
    )
}

export default TheStory