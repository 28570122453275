import React, {useState} from 'react'
import { Typography } from './Typography'
import { Row } from './Grid'
import {Link} from 'react-router-dom'
import Lottie from 'lottie-react'
import LottieAnim from '../assets/lottie/viewgallery.json'
import {motion} from 'framer-motion'

const Separator = ({text, fontSize = '16px', isLink, linkTo, lineHeight = '3px', fontFamily, includeLottie, includeLottieStart, startAnim}) => {

    const [hover, setHover] = useState(false)

    if(isLink) {
        return (
            <Row overrides={{marginTop:'10px', justifyContent:'flex-start', gap:'10px'}}>
                <Link style={{textDecoration:'none', color:'black'}} to={linkTo}>
                    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                        <Typography.Regular overrides={{ whiteSpace:'nowrap', fontFamily: fontFamily ? fontFamily : 'StolzlBold'}} fontSize={'15px'}>{text}</Typography.Regular>
                    </div>
                    <motion.div style={{width:'100%', height:'2px', backgroundColor:'#222222'}} key='5j4kl325j4kl3'
                            animate={{
                                opacity: hover ? 1 : 0
                            }}
                        />
                </Link>
                <div style={{width:'100%', height: lineHeight, backgroundColor:'#222222'}}></div>
                {includeLottie && <Lottie style={{width:'80px'}} animationData={LottieAnim}/>}
            </Row>
        )
    }

    return (
        <Row overrides={{marginTop:'10px', justifyContent:'flex-start', gap:'10px'}}>
            <Typography.Regular overrides={{whiteSpace:'nowrap', fontFamily: fontFamily ? fontFamily : 'StolzlBold'}} fontSize={fontSize} >{text}</Typography.Regular>
            {includeLottieStart && <Lottie style={{width:'40px', marginTop:'5px', marginRight:'10px', marginLeft:'0px'}} animationData={startAnim}/>}
            <div style={{width:'100%', height: lineHeight, backgroundColor:'#222222'}}></div>
        </Row>
    )
}

export default Separator