import React,{ useEffect, useRef, useContext} from 'react'
import { ContextManager } from '../../../../context/_index'
import {GridWrapper, Row, Col} from '../../../../globalComponents/Grid'
import { Typography } from '../../../../globalComponents/Typography'
import { EventsList } from '../../EventsComponents'
import { MaskedImageColumnsSmall } from '../../../../globalComponents/Image'
import Button from '../../../../globalComponents/Button'
import { Link } from 'react-router-dom'
import ImageDisplay from '../ImageDisplay'
import Fader from '../../../../globalComponents/Fader'
import Separator from '../../../../globalComponents/Separator'
import Lottie from 'lottie-react'
import LottieAnim from '../../../../assets/lottie/upcoming.json'

const Brum = ({setRef, get, pageData, imagesData, eventsData}) => {

    const {refPositions} = useContext(ContextManager.RefPosition)

    const ref = useRef()
    useEffect(() => {setRef(ref)},[])

    const scrollToContact = () => {
        refPositions?.contact?.current?.scrollIntoView({behavior:'smooth'})
    }

    return(
        <GridWrapper overrides={{marginTop:'50px'}}>

            <Fader>
            <Row>
                <Col>
                    <MaskedImageColumnsSmall image={pageData.brumImage} height='265px'/>
                </Col>
            </Row>
            </Fader>

            <Fader>
                <div style={{width:'100%', marginTop:'50px'}} ref={ref}>
                    <Separator fontSize='12px' lineHeight='2px' text={'Artsy Party - BRUM'}/>
                </div>
            </Fader>

            <Fader>
            <Row overrides={{alignItems:'flex-start', marginTop:'10px'}}>
                <Col width={10} overrides={{alignItems:'flex-start'}}>
                    <Typography.Header overrides={{fontSize:'100px', lineHeight:'80%', marginLeft:'-5px'}} bold>BRUM</Typography.Header>
                    <Typography.Regular fontSize={'17px'} overrides={{fontFamily:'StolzlBook', marginTop:'30px'}}>
                        <div dangerouslySetInnerHTML={{ __html: pageData.brumText }}></div>
                    </Typography.Regular>
                    <Button f={() => scrollToContact()} overrides={{fontSize:'15px', fontFamily:'StolzlBook', height:'40px',width:'180px', marginTop:'50px'}}>Get in Touch</Button>
                </Col>
            </Row>

            <Row overrides={{marginTop:'50px'}}>
                <Col width={10}>
                    <Link to='/imagegallery/brum' style={{width:'100%', textDecoration:'none'}}>
                        <ImageDisplay images={imagesData}/>
                            <div style={{height:'10px'}}></div>
                        <Separator text={'View Gallery'} lineHeight={'2px'} fontFamily={'StolzlRegular'} linkTo='/imagegallery/brum' isLink/>
                    </Link>
                </Col>
            </Row>
            </Fader>
            
            <Fader>
            <Row overrides={{marginTop:'80px', marginBottom:'10px'}}>
                <Col overrides={{alignItems:'flex-start'}}>
                    <Typography.Header overrides={{marginTop:'-5px'}} fontSize='55px' bold>Upcoming</Typography.Header>
                    <Row>
                        <Col width={1} overrides={{alignItems:'flex-start'}}>
                            <Typography.Header fontSize='55px' bold>Events</Typography.Header>
                        </Col>
                        <Col width={2} overrides={{alignItems:'flex-start'}}>
                            <Lottie style={{width:'80px'}} animationData={LottieAnim}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </Fader>

            <Fader>
                <EventsList events={eventsData} linkTo={'/events/brum'}/>
            </Fader>
        </GridWrapper>
    )
}

export default Brum