import React, { useContext, useEffect, useState } from 'react'
import {GridWrapper, Row} from '../../../globalComponents/Grid'
import { ContextManager } from '../../../context/_index'
import Header from './Header/Page'
import Kids from './Kids/Page'
import Community from './Community/Page'
import TheStory from './TheStory/Page'
import Contact from './Contact/Page'
import Brum from './Brum/Page'
import Loading from '../Loading'
import {motion, AnimatePresence} from 'framer-motion'

import useHomePage from '../../../dataHooks/useHomepage'
import useBrumEvents from '../../../dataHooks/useBrumEvents'
import useBrumImageGallery from '../../../dataHooks/useBrumGallery'
import useKidsEvents from '../../../dataHooks/useKidsEvents'
import useKidsImageGallery from '../../../dataHooks/useKidsGallery'
import useStoryItems from '../../../dataHooks/useStoryItems'
import usePartners from '../../../dataHooks/usePartners'
import useTestimonials from '../../../dataHooks/useTestimonials'
import { Breakpoints, ScreenTypes } from '../../../constants/windowSize'

const Home = ({scrollToContactOnLoad}) => {

    const [allLoaded, setAllLoaded] = useState(false)
    const [loadPercent, setLoadPercent] = useState(0)
    const [ready, setReady] = useState(false)

    // Get all required data using component hooks ------------------------------------------------------------------------------------
    const {getHomePage, homepageData, homepageImagesLoaded} = useHomePage()
    const {getBrumEvents, brumEventImagesLoaded, brumEventsData} = useBrumEvents()
    const {getBrumImages, brumImages, brumImagesLoaded} = useBrumImageGallery()
    const {getKidsEvents, kidsEventImagesLoaded, kidsEventsData} = useKidsEvents()
    const {getKidsImages, kidsImages, kidsImagesLoaded} = useKidsImageGallery()
    const {getStoryItems, storyImagesLoaded, storyItems} = useStoryItems()
    const {partnerImagesLoaded, partners} = usePartners()
    const {testimonialsImagesLoaded, testimonials} = useTestimonials()

    // section references ------------------------------------------------------------------------------------
    const [didScrollOnLoad, setDidScrollOnLoad] = useState(false)
    const { refPositions, setRefPositions } = useContext(ContextManager.RefPosition)
    const [brumRef, setBrumRef] = useState(null)
    const [kidsRef, setKidsRef] = useState(null)
    const [aboutRef, setAboutRef] = useState(null)
    const [contactRef, setContactRef] = useState(null)

    useEffect(() => {
        if(brumRef)
            setRefPositions({
                brum: brumRef,
                kids: kidsRef,
                about: aboutRef,
                contact: contactRef,
            })
    },[brumRef, kidsRef, aboutRef, contactRef])

    useEffect(() => {
        const calculateLoadPercent = () => {
            let val = 0
            if(homepageData) val++
            if(homepageImagesLoaded) val++
            if(brumEventsData) val++
            if(brumEventImagesLoaded) val++
            if(brumImages) val++
            if(brumImagesLoaded) val++
            if(kidsEventsData) val++
            if(kidsEventImagesLoaded) val++
            if(kidsImages) val++
            if(kidsImagesLoaded) val++
            if(storyItems) val++
            if(storyImagesLoaded) val++
            if(partners) val++
            if(partnerImagesLoaded) val++
            if(testimonialsImagesLoaded) val++
            setLoadPercent(Math.floor((100 / 15) * val))
        }
        calculateLoadPercent()
        if( homepageData && homepageImagesLoaded && brumEventsData && brumEventImagesLoaded
            && brumImages && brumImagesLoaded && kidsEventsData && kidsEventImagesLoaded
            && kidsImages && kidsImagesLoaded && storyItems && storyImagesLoaded
            && partners && partnerImagesLoaded && testimonialsImagesLoaded && testimonials ) setAllLoaded(true)

    },[homepageData, homepageImagesLoaded, brumEventsData, brumEventImagesLoaded,
        brumImages, brumImagesLoaded, kidsEventsData, kidsEventImagesLoaded,
        kidsImages, kidsImagesLoaded, storyItems, storyImagesLoaded,
        partners, partnerImagesLoaded, testimonials, testimonialsImagesLoaded])

    useEffect(() => {
        
        if(scrollToContactOnLoad && !didScrollOnLoad && refPositions?.contact?.current){
            refPositions?.contact?.current?.scrollIntoView({behavior:'smooth'})
            setDidScrollOnLoad(true)
        }
    }, [refPositions, didScrollOnLoad])

    const { screenType } = useContext(ContextManager.WindowSize)

    const wCalc = screenType === ScreenTypes.small_screen ? '450px' : '362px'

    return (
        <GridWrapper>
            <AnimatePresence>
                {!(ready && allLoaded) && <Loading setReady={setReady} loadPercent={loadPercent}/>}
                {(ready && allLoaded) &&
                    <motion.div
                        key={"unique_mainPageanimation_key"}
                        exit={{ opacity:0 }}
                        initial={{ opacity:0 }}
                        animate={{ opacity:1 }}
                        transition={{duration:1, delay: 1}}
                    >
                        <GridWrapper overrides={{width: wCalc, maxWidth: wCalc, overflowX:'hidden'}}>
                            <Header 
                                get={getHomePage} 
                                data={homepageData} 
                                imagesLoaded={homepageImagesLoaded}
                            />
                            <Brum 
                                get={getHomePage}   
                                imagesLoaded={homepageImagesLoaded}
                                setRef={setBrumRef} 
                                pageData={homepageData}
                                imagesData={brumImages}
                                eventsData={brumEventsData}
                            />
                            <Kids 
                                get={getHomePage}   
                                setRef={setKidsRef} 
                                pageData={homepageData}
                                imagesData={kidsImages}
                                eventsData={kidsEventsData}
                            />
                            <Community
                                get={getHomePage}
                                pageData={homepageData}
                                partnersData={partners}
                                testimonials={testimonials}
                                imagesLoaded={true}
                            />
                            <TheStory
                                setRef={setAboutRef}
                                data={storyItems}
                                imagesLoaded={storyImagesLoaded}
                                get={getStoryItems}
                            />
                            <Contact 
                                setRef={setContactRef} 
                                
                                data={homepageData}
                                get={getHomePage}
                            />
                        </GridWrapper>
                    </motion.div>
                }
            </AnimatePresence>
        </GridWrapper>
    )
}

export default Home