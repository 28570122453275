import { useState, useEffect } from 'react';
import { Breakpoints, ScreenTypes } from '../constants/windowSize';
import _ from 'lodash'

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
};

const mapWindowSize = (width) => {
    if (width <= Breakpoints.large_screen && width > Breakpoints.small_screen) return ScreenTypes.small_screen;
    if (width < Breakpoints.small_screen) return ScreenTypes.mobile;
    //if (width > Breakpoints.small_screen && width <= Breakpoints.med1_screen) return ScreenTypes.med1_screen;
    //if (width > Breakpoints.med1_screen && width <= Breakpoints.med2_screen) return ScreenTypes.med2_screen;
    //if (width > Breakpoints.med2_screen && width <= Breakpoints.smallLarge_screen) return ScreenTypes.smallLarge_screen;
    if (width > Breakpoints.large_screen) return ScreenTypes.large_screen;
};

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(getWindowDimensions());
    const [screenType, setScreenType] = useState(mapWindowSize);

    useEffect(() => {
        const handleResize = _.debounce(() => {
            let dimensions = getWindowDimensions();
            setWindowSize(dimensions);
            setScreenType(mapWindowSize(dimensions.width));
        }, 100);
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setScreenType(mapWindowSize(windowSize.width))
    },[windowSize])

    return { windowSize, screenType };
};

export default useWindowSize