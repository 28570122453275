export const Breakpoints = {
    //mobile: 500,
    //small_screen: 700,
    //med1_screen: 900,
    small_screen: 750,
    //med2_screen: 1100,
    //smallLarge_screen: 1300,
    large_screen: 1100
}

export const ScreenTypes = {
    mobile: 'mobile',
    small_screen: 'small_screen',
    // small_screen: 'small_screen',
    // med1_screen: 'med1_screen',
    // med2_screen: 'med2_screen',
    // smallLarge_screen: 'smallLarge_screen',
    large_screen: 'large_screen'
}