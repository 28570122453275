import { useEffect, useState } from 'react'
import {preloadImages} from '../utils/preloadImages'
import sanityClient from "@sanity/client";
import blocksToHtml from '@sanity/block-content-to-html'

// copied from sanity docs https://www.sanity.io/plugins/block-content-to-html
const h = blocksToHtml.h
const serializers = {
    types: {
      code: props => (
        h('pre', {className: props.node.language},
          h('code', props.node.code)
        )
      )
    }
}

let client = sanityClient({
  projectId: "97fmu3em",
  dataset: "production",
  useCdn: true
});

const getPageData = async () => {
    
    return await client.fetch(`
        *[_type == "mainpage"]
        {
          headerText, 
          "headerImage": headerImage.asset->url,
          brumText,
          "brumImage": brumImage.asset->url,
          "kidsImage": kidsImage.asset->url,
          kidsText,
          communityText,
          leaveReviewText,
          reviewsLink,
          "communityImageMain": communityImageMain.asset->url,
          "communityImage2": communityImage2.asset->url,
          "communityImage3": communityImage3.asset->url,
          "communityImage4": communityImage4.asset->url,
          contactEmailBrum,
          contactFacebookBrum,
          contactInstagramBrum,
          contactNumberBrum,
          contactEmailKids,
          contactFacebookKids,
          contactInstagramKids,
          contactNumberKids,
          animationDuration
        }`)
    .then(x => {return x[0]})
}

const useHomePage = () => {

    const [data, setData] = useState(null)
    const [imagesLoaded, setImagesLoaded] = useState(false)

    useEffect(() => {get()},[])

    const get = async () => {
        let response = await getPageData()
        preloadImages([
            response.headerImage,
            response.brumImage,
            response.kidsImage,
            response.communityImageMain,
            response.communityImage2,
            response.communityImage3,
            response.communityImage4
        ], setImagesLoaded)
        
        try{
          response.headerText = blocksToHtml({
            blocks: response?.headerText,
            serializers: serializers
          })
        }catch(e){response.headerText = ''}
        
        try{
          response.brumText = blocksToHtml({
            blocks: response?.brumText,
            serializers: serializers
          })
        }catch(e){response.brumText = ''}

        try{
          response.kidsText = blocksToHtml({
            blocks: response?.kidsText,
            serializers: serializers
          })
        }catch(e){response.kidsText = ''}
        
        try{
          response.communityText = blocksToHtml({
            blocks: response?.communityText,
            serializers: serializers
          })
        }catch(e){response.communityText = ''}

        setData(response)
    }
    
    return {
        getHomepage: get,
        homepageData: data,
        homepageImagesLoaded: imagesLoaded
    }
}

export default useHomePage