import React from 'react'
import { Col, GridWrapper, Row } from '../../../../globalComponents/Grid'
import { ImageV2 } from '../../../../globalComponents/Image'
import { Typography } from '../../../../globalComponents/Typography'

const StoryItemComponent = ({item, get}) => {

    return (
        <GridWrapper overrides={{marginTop:'10px'}}>
            <Row overrides={{marginTop:'25px'}}>
                <Col width={1}/>
                <Col width={3} overrides={{alignItems:'flex-start'}}>
                    <div style={{marginTop:'20px', display:'flex', justifyContent:'flex-start'}}>
                        <Typography.Regular overrides={{fontFamily:'StolzlBook'}}>
                            <div style={{maxWidth:'350px',}} dangerouslySetInnerHTML={{ __html: item.text }}></div>
                        </Typography.Regular>
                    </div>
                </Col>
                <Col width={1}/>
            </Row>
            <Row overrides={{marginTop:'15px'}}>
                <Col width={10}>
                    <ImageV2 image={item.image}/>
                </Col>
            </Row>
        </GridWrapper>
    )
}

export default StoryItemComponent