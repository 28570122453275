import React, {useContext} from 'react'
import { GridWrapper, Row, Col } from "../../../globalComponents/Grid"
import { MaskedImageColumns } from "../../../globalComponents/Image"
import { Typography, TypographyTitan } from "../../../globalComponents/Typography"
import anim from '../../../assets/lottie/brumpageseparator.json'
import Lottie from "lottie-react"
import { useState } from "react"
import {motion} from 'framer-motion'
import { useNavigate } from "react-router-dom"
import Fader from "../../../globalComponents/Fader"
import { WindowSizeStore } from '../../../context/WindowSizeStore'
import { ScreenTypes } from '../../../constants/windowSize'

const Header = ({data}) => {

    let {windowSize, screenType} = useContext(WindowSizeStore)

    let nav = useNavigate()
    const [hover, setHover] = useState(false)

    return (
        <GridWrapper>
            <Row>
                <Col>
                    <MaskedImageColumns
                        height={(windowSize.height < 700 && screenType === ScreenTypes.large_screen) ? '370px' : '500px'}
                        image={data.mainImage} 
                    />
                </Col>
            </Row>

            <Row overrides={{marginTop:'10px', justifyContent:'flex-start', gap:'10px'}}>
                <Typography.Regular overrides={{whiteSpace:'nowrap', fontFamily: 'StolzlBold'}}>A new way of socialising</Typography.Regular>
                <Lottie style={{width:'36px'}} animationData={anim}/>
                <div style={{width:'100%', height:'4px', backgroundColor:'#222222'}}></div>
                <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => nav('/')} style={{display:'flex', alignItems:'center', cursor: hover ? 'pointer' : 'default'}}>
                    <div>
                        <Typography.Regular>Back</Typography.Regular>
                            <motion.div style={{width:'100%', height:'2px', backgroundColor:'#222222'}} key='5j4kl325j4kl3'
                                animate={{
                                    opacity: hover ? 1 : 0
                                }}
                            />
                    </div>
                    <svg
                        style={{transform:"rotate(180deg)", marginRight:'-7px',}}
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        >
                        <path
                            d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"
                            fill="currentColor"
                        />
                    </svg>
                </div>
            </Row>

            <Row>
                <Col overrides={{alignItems:'flex-start'}}>
                    <Typography.Header bold>Brum Gallery.</Typography.Header>
                </Col>
            </Row>

            <Row overrides={{marginTop:'40px'}}>
                <Col>
                    <div style={{width:'100%', height:'4px', backgroundColor:'#222222'}}></div>
                </Col>
            </Row>

            <Row overrides={{marginTop:'30px'}}>
                <Col width={2} overrides={{alignItems:'flex-start'}}>
                    <Typography.Regular overrides={{fontFamily:'StolzlBook'}}>
                        <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
                    </Typography.Regular>
                </Col>
                <Col width={1}/>
            </Row>
        </GridWrapper>
    )
}

export default Header