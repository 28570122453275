import React,{useState} from 'react'
import { GridWrapper, Row, Col } from '../../globalComponents/Grid'
import { CentralizedBackgroundImage } from '../../globalComponents/Image'
import Fader from '../../globalComponents/Fader'


const Images = ({images, openImage}) => {

    const [hover, setHover] = useState(false)

    let rows = []
    let size = 3

    //split partners into rows of size
    for (let i = 0; i < images.length; i += size) rows.push(images.slice(i, i + size))
    
    if(rows.length > 0)
        //if the last row has < size. Add null elements
        while(rows[rows.length - 1].length < size) rows[rows.length - 1].push(null)

    const handleOpenImage = (e, {indexRow, indexCol}) => {
        e.stopPropagation()
        openImage(rows[indexRow][indexCol].image)
    }

    return (
        <GridWrapper>
            {rows.map((row, indexRow) => {
                return (
                    <Fader key={`imagegallery${indexRow}`}>
                        <Row overrides={{justifyContent:'space-between', height:'150px', marginBottom:'10px'}}>
                            {row.map((col, indexCol) => {
                                if(col === null) return <Col overrides={{marginRight:'10px'}}></Col>
                                return (
                                    <Col key={`imagegallery${indexRow}_${indexCol}`} overrides={{marginRight:'10px'}}>
                                        <div 
                                            onClick={(e) => handleOpenImage(e, {indexRow, indexCol})} 
                                            style={{
                                                width:'100%', 
                                                height:'100%', 
                                                cursor: hover ? 'pointer' : 'default'
                                            }}
                                            onMouseEnter={() => setHover(true)}
                                            onMouseLeave={() => setHover(false)}
                                        >
                                            <CentralizedBackgroundImage image={col.image}/>
                                        </div>
                                    </Col>
                                )
                            })}        
                        </Row>
                    </Fader>
                )
            })}
        </GridWrapper>
    )
}

export default Images