import React, { useState } from 'react'
import { WindowSizeStore } from '../context/WindowSizeStore'
import { RefPositionStore } from './RefPositionStore'
import useWindowSize from '../globalHooks/useWindowSize'


export const ContextProvider = ({children}) => {

    const {windowSize, screenType} = useWindowSize()
    const [refPositions, setRefPositions] = useState(null)

    return (
        <div>
            <WindowSizeStore.Provider value={{windowSize: windowSize, screenType: screenType}}>
            <RefPositionStore.Provider value={{refPositions: refPositions, setRefPositions: setRefPositions}}>
                {children}
            </RefPositionStore.Provider>
            </WindowSizeStore.Provider>
        </div>
    );
}
  
export const ContextManager = {
    WindowSize: WindowSizeStore,
    RefPosition: RefPositionStore
}