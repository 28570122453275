import React,{useContext, useEffect, useState} from 'react'
import Button from '../../../globalComponents/Button'
import { GridWrapper, Row, Col } from '../../../globalComponents/Grid'
import { ImageV2, CentralizedBackgroundImage } from '../../../globalComponents/Image'
import { Typography } from '../../../globalComponents/Typography'
import Arrow from '../../../assets/images/arrow.png'
import Header from './Header'
import Images from '../Images'
import useKidsImageGallery from '../../../dataHooks/useKidsGallery'
import useKidsImagesPage from '../../../dataHooks/useKidsImagesPage'
import {motion, AnimatePresence} from 'framer-motion'
import Loading from '../Loading'
import { ContextManager } from '../../../context/_index'
import { ScreenTypes } from '../../../constants/windowSize'

const KidsImageGallery = () => {

    const [ready, setReady] = useState(false)
    const [showControls, setShowControls] = useState(false)

    const { 
        kidsImagesPageData,
        getKidsImagesPage,
        kidsImagesPageImagesLoaded
    } = useKidsImagesPage()

    const {
        kidsImages,
        getKidsImages,
        kidsImagesLoaded,
        onNext,
        onPrev,
        openImage,
        closeImage,
        modalOpen,
        itemInView
    } = useKidsImageGallery()

    const { screenType } = useContext(ContextManager.WindowSize)

    return (
        <GridWrapper>
            <AnimatePresence>
            {!(ready && kidsImages && kidsImagesLoaded && kidsImagesPageData && kidsImagesPageImagesLoaded) && <Loading setReady={setReady}/>}
            {(ready && kidsImages && kidsImagesLoaded && kidsImagesPageData && kidsImagesPageImagesLoaded) && 
                <motion.div
                    key={"unique_mainPageanimation_key"}
                    exit={{ opacity:0 }}
                    initial={{ opacity:0 }}
                    animate={{ opacity:1 }}
                    transition={{duration:1, delay: 1}}
                >
                    <div onClick={(e) => {e.stopPropagation(); setShowControls(!showControls)}} style={{
                            display: modalOpen ? 'inline-block' : 'none', 
                            position:'fixed',
                            zIndex:'1', 
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                            width:'92%', 
                            maxWidth:'1250px',
                            height:'96vh', 
                            overflow:'hidden', 
                            backgroundColor:'black',
                            padding:'10px'
                        }} 
                    >
                        <CentralizedBackgroundImage image={kidsImages[itemInView].image} overrides={{backgroundSize:'contain', backgroundRepeat:'no-repeat'}}>
                            <GridWrapper overrides={{height:'100%', justifyContent:'flex-start'}}>
                                <Row overrides={{height:'35px', marginTop:'10px'}}>
                                    <Col overrides={{alignItems:'flex-end', flexDirection:'row'}}>
                                        <Col width={25} overrides={{alignItems:'flex-start'}}>
                                            <Button f={(e) => closeImage()} overrides={{marginRight:'8px', width:'40px', backgroundColor:'#D9D9D9'}}>
                                                <Typography.Regular bold fontSize={'12px'} overrides={{color:'#000000'}}>X</Typography.Regular>
                                            </Button>
                                        </Col>
                                        <Col width={15}>
                                            <Button f={(e) => {onPrev(); e.stopPropagation()}} overrides={{marginRight:'5px', width:'100%', backgroundColor:'#D9D9D9'}}>
                                                <Typography.Regular bold fontSize={'12px'} overrides={{color:'#000000', fontFamily:'StolzlBook'}}>
                                                    <Row>
                                                        <ImageV2 overrides={{width:'15px', height:'7px', marginRight:'10px', transform:'rotate(180deg)'}} image={Arrow}/> Prev.
                                                    </Row>
                                                </Typography.Regular>
                                            </Button>
                                        </Col>
                                        <Col width={15}>
                                            <Button f={(e) => {onNext(); e.stopPropagation()}} overrides={{width:'100%', backgroundColor:'#D9D9D9'}} bold>
                                                <Typography.Regular bold fontSize={'12px'} overrides={{color:'#000000', fontFamily:'StolzlBook'}}>
                                                    <Row>
                                                        Next. 
                                                        <ImageV2 overrides={{width:'15px', height:'7px', marginLeft:'10px'}} image={Arrow}/>
                                                    </Row>
                                                </Typography.Regular>
                                            </Button>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row overrides={{height:'100%'}}>
                                    <Col overrides={{alignItems:'flex-start', justifyContent:'flex-end'}}>
                                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center', height:'35px', width:'140px', backgroundColor:'#D9D9D9'}} bold>
                                            <Typography.Regular fontSize={'12px'} overrides={{color:'#000000', fontFamily:'StolzlBook'}} bold>
                                                Exhibit - {itemInView + 1} / {kidsImages.length}
                                            </Typography.Regular>
                                        </div>
                                    </Col>
                                </Row>
                            </GridWrapper>
                        </CentralizedBackgroundImage>
                    </div>
                    <GridWrapper overrides={{width:screenType === ScreenTypes.mobile ? '360px' : '450px', marginBottom:'100px', overflowX:'hidden'}}>
                        <Row>
                            <Col>
                                <Header data={kidsImagesPageData}/>
                            </Col>
                        </Row>
                        <Row overrides={{alignItems:'flex-start', marginTop:'30px'}}>
                            <Col width={13} overrides={{justifyContent:'flex-start'}}>
                                <Images openImage={openImage} images={kidsImages}/>
                            </Col>
                        </Row>
                    </GridWrapper>
                </motion.div>}
            </AnimatePresence>
        </GridWrapper>
    )

}

export default KidsImageGallery