import { GridWrapper, Row, Col } from './Grid'
import {generateRandomString} from '../utils/randomStringGenerator'
import Button from './Button'

export const ImageV2 = ({image, overrides, loaded}) => {
    return (
        <GridWrapper>
            <img alt='' src={image} style={{width:'100%', ...overrides}}/>
        </GridWrapper>
    )
}

export const CentralizedBackgroundImage = ({children, image, overrides, loaded}) => {

    return (
        <div style={{
            width:'100%', 
            height:'100%', 
            backgroundImage:`url(${image})`, 
            backgroundSize:'cover', 
            backgroundPosition:'center',
            ...overrides
        }}>
            {children}
        </div>
    )
}

export const MaskedImageColumns = ({image, height, withHomeButton = false}) => {

    const parent = {
        width:'100%',
        height: height,
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',
        display:'flex',
        justifyContent:'space-evenly'
    }

    const col = {
        width:'15px',
        height:'100%',
        backgroundColor:'#f6f6f6'
    }

    return(
        <div style={parent}>
            <div style={col}/>
            <div style={col}/>
            <div style={col}/>
            <div style={col}/>
        </div>
    )
}

export const MaskedImageColumnsSmall = ({image, height}) => {

    const parent = {
        width:'100%',
        height: height,
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',
        display:'flex',
        justifyContent:'space-evenly'
    }

    const col = {
        width:'10px',
        height:'100%',
        backgroundColor:'#f6f6f6'
    }

    return(
        <div style={parent}>
            <div style={col}/>
            <div style={col}/>
        </div>
    )
}