export const preloadImages = async (images, setImagesLoaded) => {
    
    const promises = await images.map((x) => {
        return new Promise((res,rej) => {
            const img = new Image()
            img.src = x
            img.onload = res()
            img.onerror = rej()
        })
    })

    await Promise.allSettled(promises)
    setImagesLoaded(true)
}